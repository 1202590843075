@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
	font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
	color: #000000;
	margin: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: var(--black);
	text-decoration: none;
}

.hr {
	width: 100%;
	opacity: 0.4;
	border: solid 0.25px white;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
}

.highlight {
	color: var(--primary-color) !important;
	font-weight: 900 !important;
}

* {
	margin: 0;
	padding: 0;
	line-height: 150%;
	-webkit-tap-highlight-color: transparent;
}

*:focus {
	outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

textarea, 
input[type=text], 
input[type=password], 
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

:root {
	--primary-color: #2fa67f;
	--secondary-color: #e97047;
	--light-blue: #f7fffc;
	--light-green: #eaf6f2;
	--black: #1a1a1a;
	--dark-green-text: #0e3226;
	--dark-gray-text: #484848;
	--light-gray: #d1d1d1;
	--light-gray-text: #a3a3a3;
	--danger: #ff5858;
}

.base {
	padding: 0px 32px 0px;
	display: flex;
	justify-content: center;
}

.base-container {
	max-width: 1440px;
	width: 100%;
}

@media only screen and (max-width: 767px) {
	.base {
		padding: 0px 16px;
	}
}

.button.MuiButton-root {
	text-transform: none;
	text-transform: initial;
	border-radius: 8px;
	height: 60px;
	font-weight: 700;
	font-size: 20px;
}

.button.MuiButton-outlined {
	color: var(--primary-color);
	border-color: var(--primary-color);
}

.button.MuiButton-outlined.Mui-disabled {
	opacity: .4;
	color: var(--black);
}

.button.MuiButton-contained {
	background-color: var(--primary-color);
	color: white;
}

.button.MuiButton-contained.Mui-disabled {
	background-color: var(--primary-color);
	opacity: .4;
	color: white;
}

.button.MuiButton-contained:hover {
	box-shadow: 0px 8px 24px rgba(47, 166, 127, 0.16);
	background-color: var(--primary-color);
}

@media only screen and (max-width: 767px) {
	.button.MuiButtonBase-root {
		height: 48px;
		font-size: 16px;
	}
}
.app-bar {
	position: fixed;
	background: white;
	z-index: 999;
	width: calc(100% - 64px);
}

.offset {
	height: 72px;
}

.app-bar .app-bar-container {
	display: flex;
	justify-content: space-between;
	min-height: 72px;
	font-size: 20px;
	font-weight: bold;
	padding-right: 32px;
}

.app-bar .app-bar-left,
.app-bar .app-bar-right {
	display: flex;
	align-items: center;
}

.app-bar .logo {
	width: 182px;
	margin-right: 82px;
	cursor: pointer;
}

.app-bar .menu,
.app-bar .menu-nav,
.app-bar .dropdown {
	display: flex;
	align-items: center;
}

.app-bar .menu-nav {
	margin-right: 56px;
}

.app-bar .menu,
.app-bar .auth,
.app-bar .switch {
	margin-top: 3px;
}

.app-bar .auth {
	color: var(--primary-color);
	cursor: pointer;
}

.app-bar .switch {
	margin-right: 24px;
}

@media only screen and (max-width: 1279px) {
	.app-bar .app-bar-container {
		min-height: 64px;
	}

	.offset {
		height: 64px;
	}

	.app-bar .menu,
	.app-bar .auth,
	.app-bar .switch {
		display: none;
	}

	.app-bar .logo {
		margin-right: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.app-bar {
		padding: 0px 16px;
		width: calc(100% - 32px);
	}

	.app-bar .app-bar-container {
		min-height: 48px;
	}

	.offset {
		height: 48px;
	}

	.app-bar .logo {
		width: auto;
	}

	.app-bar .logo,
	.app-bar .mobile-menu-icon {
		height: 24px;
	}
}

.app-bar-menu-list {
	cursor: pointer;
}

.app-bar-menu-list ul li {
	padding: 16px;
	font-size: 20px;
}

.app-bar-menu-list .popper {
	top: 10px !important;
	left: 7px !important;
}

.app-bar-menu-list .menu-list-arrow {
	padding-left: 5px;
	transition: 0.3s ease-in-out;
}

.app-bar-menu-list .menu-list-arrow.open {
	transform: rotate(180deg);
}

.app-bar-menu-list .MuiButtonBase-root {
	cursor: auto;
	cursor: initial;
}

.sub-bar .sub-bar-container {
	padding: 14px 42px;
}

.sub-bar a {
	margin-right: 48px;
	font-weight: 700;
	font-size: 24px;
	line-height: 36px;
	color: var(--light-gray-text);
}

@media only screen and (max-width: 1279px) {
	.sub-bar .sub-bar-container {
		padding: 12px 0px;
	}
}

@media only screen and (max-width: 767px) {
	.sub-bar .sub-bar-container {
		padding: 8px 0px;
	}
	
	.sub-bar a {
		margin-right: 24px;
		font-size: 16px;
		line-height: 24px;
	}
}

.mobile-menu {
	margin-right: 28px;
	display: none;
}

.mobile-menu .mobile-menu-icon {
	color: var(--primary-color);
}

.drawer-menu {
	position: relative;
}

.drawer-menu .MuiBackdrop-root {
	background-color: var(--primary-color);
	opacity: 0.4 !important;
}

.drawer-menu .MuiPaper-root {
	width: 296px;
	padding: 16px 0px;
	background-color: var(--primary-color);
}

.drawer-menu .logo-white {
	margin-bottom: 20px;
	padding: 0px 32px;
	cursor: pointer;
}

.drawer-menu .switch-wrapper {
	position: absolute;
	bottom: calc(33px + 16px);
	right: 16px;
}

@media only screen and (max-width: 1279px) {
	.mobile-menu {
		margin-right: 20px;
		display: block;
	}

	.mobile-menu .mobile-menu-icon {
		display: block;
		top: -2px;
		position: relative;
		cursor: pointer;
	}
}

@media only screen and (max-width: 767px) {
	.mobile-menu {
		margin-right: 16px;
	}

	.drawer-menu .MuiPaper-root {
		width: 208px;
	}

	.drawer-menu .logo-white {
		padding: 0px 16px;
	}
}

.title {
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	color: var(--primary-color);
	margin-bottom: 24px;
}

.title-text {
	margin-right: 24px;
	display: inline-block;
}

.line {
	height: 24px;
	width: 80px;
	border-bottom: solid 2px var(--primary-color);
	display: inline-block;
	vertical-align: top;
}

@media only screen and (max-width: 767px) {
	.title {
		font-size: 20px;
		line-height: 30px;
	}

	.title-text {
		margin-right: 12px;
	}

	.line {
		height: 15px;
		width: 40px;
		border-bottom: solid 1px;
	}
}

.footer {
	background-color: #fafffd;
}

.footer .footer-container {
	padding: 58px 32px 24px;
}

.footer .cols {
	display: flex;
	justify-content: space-between;
	line-height: 24px;
}

.footer .col {
	display: flex;
	flex-direction: column;
}

.footer img.logo {
	width: 159px;
	margin-bottom: 42px;
}

.footer .social-media {
	margin: 0px 0px 25px;
	font-size: 16px;
	line-height: 24px;
}

.footer .social-media img {
	margin-right: 13px;
}

.footer .address-one {
	font-weight: 700;
	margin-bottom: 8px;
}

.footer .address-two {
	margin-bottom: 4px;
}

.footer .copyright {
	margin-top: 71px;
	text-align: center;
	color: var(--dark-gray-text);
}

.footer .col:not(:first-child) > div,
.footer .col:not(:first-child) > a {
	font-size: 16px;
	margin-bottom: 24px;
	line-height: 24px;
}

.footer .mobile,
.footer .mobile-phone {
	display: none;
}

.footer .all {
	display: block !important;
}

.footer .link {
	cursor: pointer;
}

@media only screen and (max-width: 1279px) {
	.footer .desktop {
		display: none;
	}

	.footer .mobile {
		display: block;
	}

	.footer .col {
		width: 35%;
	}

	.footer .col:not(:first-child) > div,
	.footer .col:not(:first-child) > a {
		margin-bottom: 17px;
	}

	.footer .last {
		margin-bottom: 69px !important;
	}
	
}

@media only screen and (max-width: 767px) {
	.footer .mobile-phone {
		display: block;
	}

	.footer .non-mobile-phone {
		display: none;
	}

	.footer .col {
		width: 100%;
		text-align: center;
	}

	.footer .logo {
		margin: auto;
	}

	.footer .col:not(:first-child) > div,
	.footer .col:not(:first-child) > a {
		margin-bottom: 12px;
	}

	.footer .last {
		margin-bottom: 32px !important;
	}
}

@media only screen and (max-width: 480px) {
	.footer .col {
		text-align: left;
		text-align: initial;
	}

	.footer .logo {
		margin: 0px;
	}

}

.search-bar { 
  position: relative; 
  padding: 0; 
  margin: 0; 
}

.search-bar input[type="text"] { 
  margin:0; 
  padding: 15px 44px 15px 44px;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  border: 1px solid #767676;
  border-radius: 8px;
  width: 100%;
}

.search-bar .search-icon { 
  position: absolute; 
  top: 50%;
  transform: translateY(-50%); 
  left: 16px; 
  width: 20px; 
  height: 20px; 
}

.search-bar .close {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #767676;
}

@media only screen and (max-width: 600px) {
  .search-bar input[type="text"] { 
    padding: 11px 40px;
    font-size: 14px;
    line-height: 21px;
  }

  .search-bar .search-icon { 
    position: absolute; 
    top: 50%;
    transform: translateY(-50%); 
    left: 16px; 
    width: 16px; 
    height: 16px; 
  }
}

.preapproval {
	background-color: #f5fffc;
}

.preapproval .preapproval-container {
	max-width: 1280px;
	margin: auto;
	background-color: white;
}

.preapproval .content {
	max-width: 600px;
	margin: 48px auto 0px;
}

.preapproval .title {
	font-size: 40px;
	line-height: 58px;
	margin-bottom: 32px;
	width: 100%;
}

.preapproval .buttons {
	margin-top: 80px;
}

.preapproval .button {
	width: 207px;
	margin-right: 28px;
}

.preapproval .toast {
	position: absolute;
	top: 0px;
	left: auto;
	margin-left: -32px;
}

.preapproval .toast .toast-item {
	width: 1280px;
}

.preapproval .small-text span {
  color: var(--primary-color);
  font-weight: bold;
}

.preapproval .MuiCircularProgress-colorPrimary {
  color: #ffffff;
  width: 30px !important;
  height: 30px !important;
}

@media only screen and (min-width: 1279px) {
	.preapproval, .preapproval .preapproval-container{
		height: 100% !important;
		min-height: 100vh;
	}
}

@media only screen and (max-width: 1279px) {
	.preapproval .toast {
		left: 0px;
		margin-left: 0;
	}

	.preapproval .toast .toast-item {
		width: 100vw;
	}
}

@media only screen and (max-width: 767px) {
	.preapproval .content {
		max-width: 472px;
		margin: 80px auto 0px;
	}

	.preapproval .title {
		font-size: 32px;
		line-height: 48px;
		margin-bottom: 24px;
	}

	.preapproval .buttons {
		margin-top: 72px;
	}
}

@media only screen and (max-width: 600px) {
	.preapproval .content {
		margin: 24px auto 0px;
	}

	.preapproval .title {
		font-size: 24px;
		line-height: 36px;
	}

	.preapproval .buttons {
		margin-top: 56px;
		display: flex;
		justify-content: space-between;
	}

	.preapproval .button {
		width: calc(50% - 8px);
		margin-right: 0px;
	}

	.preapproval .MuiCircularProgress-colorPrimary {
		width: 21px !important;
		height: 21px !important;
	}
}

.preapproval-bar {
	background: white;
	z-index: 999;
	width: calc(1280px - 64px);
	max-width: 1280px;
}

.preapproval-bar .mobile-menu {
	display: block;
}

.preapproval-bar .title {
	font-size: 24px;
	margin-bottom: 0px;
}

.preapproval-bar + .offset {
	border-bottom: solid 3px var(--primary-color);
	height: 0px;
}

.preapproval-bar .preapproval-bar-container {
	display: flex;
	justify-content: space-between;
	min-height: 72px;
	font-size: 20px;
	font-weight: bold;
	padding-right: 32px;
}

.preapproval-bar .preapproval-bar-left,
.preapproval-bar .preapproval-bar-right {
	display: flex;
	align-items: center;
}

.preapproval-bar .menu,
.preapproval-bar .menu-nav,
.preapproval-bar .dropdown {
	display: flex;
	align-items: center;
}

.preapproval-bar .menu-nav {
	margin-right: 56px;
}

.preapproval-bar .menu,
.preapproval-bar .auth {
	margin-top: 3px;
}

.preapproval-bar .auth {
	color: var(--primary-color);
	cursor: pointer;
}

.preapproval-bar .logo {
	width: 182px;
	cursor: pointer;
}

@media only screen and (max-width: 1279px) {
	.preapproval-bar {
		width: calc(100% - 64px);
	}
	.preapproval-bar .preapproval-bar-container {
		min-height: 64px;
	}

	.preapproval-bar .menu,
	.preapproval-bar .auth {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.preapproval-bar {
		padding: 0px 16px;
		width: calc(100% - 32px);
	}

	.preapproval-bar .preapproval-bar-container {
		min-height: 48px;
	}

	.preapproval-bar .logo {
		width: auto;
	}

	.preapproval-bar .logo,
	.preapproval-bar .mobile-menu-icon {
		height: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.preapproval-bar .title {
		font-size: 16px;
		margin-bottom: 0px;
	}
}
.preapproval-success .preapproval-success-container {
	max-width: 1600px;
	position: relative;
	margin: auto;
}

.preapproval-success .content {
	padding: 75px 0px 0px 128px;
	max-width: 735px;
}

.preapproval-success .logo {
	width: 249px;
	margin-bottom: 32px;
}

.preapproval-success .title {
	font-size: 40px;
	line-height: 58px;
	font-weight: 700;
	color: var(--black);
	margin-bottom: 24px;
}

.preapproval-success .title span {
	color: var(--primary-color);
}

.preapproval-success .description {
	font-size: 24px;
	color: var(--dark-gray-text);
	margin-bottom: 72px;
}

.preapproval-success button {
	padding-left: 24px;
	padding-right: 24px;
}

.preapproval-success .hill-houses {
	position: absolute;
	bottom: 0px;
	left: calc(1600px - 833px);
}

@media only screen and (max-width: 1024px) {
	.preapproval-success .preapproval-success-container {
		max-width: 1600px;
		width: 100%;
		position: static;
		position: initial;
	}

	.preapproval-success .content {
		padding: 30px 50px 0px 50px;
		max-width: 650px;
		margin: auto;
	}

	.preapproval-success .logo {
		width: 175px;
	}

	.preapproval-success .description {
		margin-bottom: 56px;
	}

	.preapproval-success .hill-houses {
		width: 100vw;
		left: 0px;
		max-height: 20vh;
	}
}

@media only screen and (max-width: 768px) {
	.preapproval-success .content {
		padding: 21px 15px 0px 15px;
	}

	.preapproval-success .logo {
		width: 175px;
	}

	.preapproval-success .title {
		font-size: 32px;
		line-height: 150%;
	}

	.preapproval-success .description {
		font-size: 20px;
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 480px) {
	.preapproval-success .logo {
		width: 175px;
	}

	.preapproval-success .description {
		font-size: 14px;
	}

	.preapproval-success .title {
		font-size: 20px;
		margin-bottom: 16px;
	}
}

.begin-preapproval .image {
	margin: auto;
	display: block;
	margin-bottom: 48px;
	width: 320px;
	height: 252px;
}

.begin-preapproval .title {
	font-weight: bold;
	font-size: 32px;
	line-height: 48px;
	color: var(--black);
	margin: auto;
	margin-bottom: 24px;
}

.begin-preapproval .title,
.begin-preapproval .buttons,
.begin-preapproval .button {
	max-width: 472px;
	width: 100%;
}

.begin-preapproval .buttons {
	margin: auto;
	display: flex;
	flex-direction: column;
}

.begin-preapproval .button {
	margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
	.begin-preapproval .image {
		width: 380px;
		height: 300px;
		margin-bottom: 80px;
	}
}

@media only screen and (max-width: 600px) {

	.begin-preapproval .content {
		padding-top: 8px;
	}

	.begin-preapproval .image {
		width: 179.03px;
		height: 140px;
		margin-bottom: 32px;
	}

	.begin-preapproval .title {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 16px;
	}

	.begin-preapproval .button {
		margin-bottom: 16px;
	}
}
.text-field,
.form-input,
.form-input-label {
  box-sizing: border-box;
}

.text-field {
  position: relative;
}

.text-field .text-field-container {
  position: relative;
}

.text-field .form-input, 
.text-field .form-input-label {
  font-size: 20px;
  line-height: 30px;
}

.text-field .form-input {
  background: none;
  background-color: white;
  color: var(--black);
  display: block;
  border: none;
  border: 1px solid #767676;
  border-radius: 8px;
  width: 100%;
  padding: 25px 16px 11px 16px;
}

.text-field .form-input:hover, 
.text-field .form-input.focus {
  border: 1px solid var(--primary-color);
}

.text-field .form-input.disabled {
  border: 1px solid var(--light-gray-text);
}

.text-field .form-input.error {
  border: 1px solid var(--danger);
}

.text-field .form-input[type="password"]{
  letter-spacing: 2px;
}

.text-field .form-input-label {
  color: #767676;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  transform: translateY(19px);
  top: 0px;
  left: 16px;
  transition: 0.1s ease-out all;
}

.text-field:hover .form-input-label, 
.text-field .form-input-label.focus {
  color: var(--primary-color)
}

.text-field .form-input-label.disabled {
  color: var(--light-gray-text)
}

.text-field .form-input-label.shrink,
.text-field .message-label {
  font-size: 12px;
  line-height: 18px;
}

.text-field .form-input-label.shrink {
  transform: translateY(8px);
}

.text-field .form-input-label.error,
.text-field .message-label.error {
  color: var(--danger); 
}

.text-field .message-label {
  margin: 0 16px 0 16px;
  position: absolute;
  color: #767676
}

/* Remove arrow on type number */

.text-field input::-webkit-outer-spin-button,
.text-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-field input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 767px) {
  .text-field .form-input-label {
    font-size: 16px;
    line-height: 24px;
    transform: translateY(18px);
  }

  .text-field .form-input {
    padding: 27px 16px 11px 16px;
    font-size: 16px;
    line-height: 18px;
  }

  .text-field .form-input-label.shrink {
    transform: translateY(6px);
  }
}

@media only screen and (max-width: 600px) {
  .text-field .form-input,
  .text-field .form-input-label {
    font-size: 14px;
    line-height: 21px;
  }

  .text-field .form-input {
    padding: 18px 12px 7px 12px;
    border-radius: 4px;
  }

  .text-field .form-input-label {
    left: 12px;
    transform: translateY(14px);
  }

  .text-field .form-input-label.shrink,
  .text-field .message-label {
    font-size: 10px;
    line-height: 15px;
  }

  .text-field .form-input-label.shrink {
    transform: translateY(4px);
  }

  .text-field .message-label {
    margin: 0 12px 0 12px;
  }
}


.file-upload-dropzone .base {
  height: 311px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F4F4F4;
  border: 1px dashed var(--light-gray-text);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.file-upload-dropzone .base.reject {
  background: #FFECEC;
  border: 1px dashed #D65B5B;
}

.file-upload-dropzone .base.accept {
  background: #F8FEFC;
  border: 1px dashed var(--primary-color);
}

.file-upload-dropzone .label-title {
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #767676;
}

.file-upload-dropzone .label-subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #767676;
  margin-top: 8px;
}

.file-upload-dropzone .image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.file-upload-dropzone .drag-and-drop-image {
  width: 32px;
  height: 32px;
}

.file-upload-dropzone .message-label {
  position: absolute;
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 0 16px;
}

.file-upload-dropzone .message-label.reject {
  color: var(--danger); 
}


@media only screen and (max-width: 767px) {
  .file-upload-dropzone .base {
    height: 206px;
  }

  .file-upload-dropzone .label-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .file-upload-dropzone .label-subtitle {
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }

  .file-upload-dropzone .image-container {
    margin-bottom: 19px
  }

  .file-upload-dropzone .drag-and-drop-image {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .file-upload-dropzone .base {
    height: 121px;
  }

  .file-upload-dropzone .label-title {
    font-size: 14px;
    line-height: 21px;
  }
  
  .file-upload-dropzone .label-subtitle {
    font-size: 10px;
    line-height: 18px;
    margin-top: 2px;
  }

  .file-upload-dropzone .image-container {
    margin-bottom: 8px
  }

  .file-upload-dropzone .message-label {
    font-size: 10px;
    line-height: 15px;
    margin: 0px 0 0 12px;
  }
}
.custom-accordion .MuiAccordion-root {
	box-shadow: none;
	border-bottom: 1px solid #e8e8e8;
}

.custom-accordion .MuiAccordion-rounded:last-child { 
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.custom-accordion .MuiAccordion-rounded:first-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;

}

.custom-accordion .MuiAccordion-root::before {
	height: 0px;
}

.custom-accordion .MuiAccordion-root.Mui-expanded {
	margin: 0px;
}
.custom-accordion-summary .MuiAccordionSummary-root.Mui-expanded {
	min-height: 0;
}

.custom-accordion-summary .MuiAccordionSummary-root {
	padding: 0;
}

.custom-accordion-summary .MuiAccordionSummary-content {
	margin: 24px 0;
}

.custom-accordion-summary .MuiAccordionSummary-content.Mui-expanded {
	margin: 24px 0;
}

@media only screen and (max-width: 767px) {
  .custom-accordion-summary .MuiAccordionSummary-content {
		margin: 16px 0;
	}

	.custom-accordion-summary .MuiAccordionSummary-content.Mui-expanded {
		margin: 16px 0;
	}
}

@media only screen and (max-width: 600px) {
  .custom-accordion-summary .MuiAccordionSummary-content {
		margin: 12px 0;
	}

	.custom-accordion-summary .MuiAccordionSummary-content.Mui-expanded {
		margin: 12px 0;
	}
}
.custom-accordion-details .MuiAccordionDetails-root {
	padding: 0 24px 24px 0;
	display: block;
}

@media only screen and (max-width: 767px) {
  .custom-accordion-details .MuiAccordionDetails-root {
		padding: 0 22px 18px 0;
	}
}

@media only screen and (max-width: 600px) {
  .custom-accordion-details .MuiAccordionDetails-root {
		padding: 0 20px 12px 0;
	}
}
.address-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

.address-preapproval .input-container.col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
}

.address-preapproval .city-district-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px
}

@media only screen and (max-width: 767px) {
  .address-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }

  .address-preapproval .city-district-container {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    gap: 0px;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .address-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }
  
  .address-preapproval .input-container.col-2 {
    grid-gap: 16px;
    gap: 16px;
  }
  
  .address-preapproval .city-district-container {
    margin-bottom: 24px;
  }
}



.step-container.base {
  display: block;
}

.step-container .buttons {
  margin-bottom: 72px;
}
.search-preapproval .dropdown-container {
  margin-top: 16px;
  background-color: white;
  z-index: 3;
  width: 100%;
  transition: visibility 200ms ease-out;
  box-sizing: border-box;
}

.search-preapproval .suggestion-item {
  padding: 16px 20px;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 24px;
  color: var(--black);
  cursor: pointer;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
} 

.search-preapproval .suggestion-item:first-child {
  border-top: 1px solid #e8e8e8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.search-preapproval .suggestion-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.search-preapproval .suggestion-item {
  border-bottom: 1px solid #e8e8e8;
}

.search-preapproval .text-gray {
  color: #767676;
}

.search-preapproval .text-black {
  color: var(--black);
}
.search-preapproval .suggestion-item.not-found {
  padding: 16px;
  display: flex;
  align-items: flex-start;
}

.search-preapproval .gray-marker {
  height: 24px;
  width: 24px;
  margin-top: 3px;
}

.search-preapproval .item-text-container {
  margin-left: 12px;
}

.search-preapproval .text-top {
  font-size: 20px;
  line-height: 30px;
  color: #767676;
}

.search-preapproval .text-bottom {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-color);
}

@media only screen and (max-width: 767px) {
  .search-preapproval .suggestion-item {
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
  }
  
  .search-preapproval .gray-marker {
    height: 16px;
    width: 16px;
    margin-top: 4px;
  }

  .search-preapproval .item-text-container {
    margin-left: 16px;
  }

  .search-preapproval .text-top {
    font-size: 16px;
    line-height: 24px;
  }
  
  .search-preapproval .text-bottom {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .search-preapproval .suggestion-item {
    padding: 12px;
    font-size: 12px;
    line-height: 21px;
  } 

  .search-preapproval .suggestion-item.not-found {
    padding: 12px;
  }

  .search-preapproval .gray-marker {
    margin-top: 3px;
  }

  .search-preapproval .item-text-container {
    margin-left: 4px;
  }

  .search-preapproval .text-top {
    font-size: 12px;
    line-height: 21px;
  }
  
  .search-preapproval .text-bottom {
    font-size: 12px;
    line-height: 21px;
  }

  .search-preapproval .title {
    transition: 0.4s ease-out transform;
  }
}
.radio-button {
  position: relative;
  display: flex;
}

.radio-button label {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--dark-gray-text);
  cursor: pointer;
  padding: 15px 24px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.radio-button input[type=radio] {
  display: none;
}

.radio-button label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 100%;
}

.radio-button input[type=radio]:checked + label {
  background: #E6F4F0;
}

.radio-button input[type=radio]:checked + label::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  border-radius: 100%;
}

@media only screen and (max-width: 767px) {
  .radio-button label {
    font-size: 16px;
    line-height: 26px;
  }

  .radio-button label::before {
    width: 20px;
    height: 20px;
    margin-right: 28px;
  }

  .radio-button input[type=radio]:checked + label::after {
    content: "";
    height: 14px;
    width: 14px;
    left: 27px;
  }
}

@media only screen and (max-width: 600px) {
  .radio-button label {
    font-size: 14px;
    line-height: 26px;
    padding: 9px 12px;
    vertical-align: middle;
    vertical-align: middle;
  }

  .radio-button label::before {
    margin-right: 24px;
  }

  .radio-button input[type=radio]:checked + label::after {
    content: "";
    height: 12px;
    width: 12px;
    left: 16px;
  }
}
.property-details-preapproval .field-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 24px;
}

.property-details-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
}

.property-details-preapproval .fields-container {
  margin: 48px 0;
}

.property-details-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  .property-details-preapproval .field-title {
    font-size: 20px;
    line-height: 30px;
  }

  .property-details-preapproval .field-title:first-of-type {
    margin-top: 40px;
  }

  .property-details-preapproval .fields-container {
    margin: 40px 0;
  }

  .property-details-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .property-details-preapproval .field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .property-details-preapproval .col-2 {
    grid-gap: 16px;
    gap: 16px;
  }

  .property-details-preapproval .field-title:first-of-type {
    margin-top: 0px;
  }

  .property-details-preapproval .fields-container {
    margin: 24px 0 32px 0;
  }

  .property-details-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }

  .property-details-preapproval .custom-radio-container {
    grid-template-columns: 1fr;
    grid-gap: 8px;
    gap: 8px;
  }
}




.select-field .select-field-container {
  position: relative;
}

.select-field .form-input-label {
  font-size: 20px;
  line-height: 30px;
  color: #767676;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  transform: translateY(19px);
  top: 0px;
  left: 16px;
  transition: 0.1s ease-out all;
}

.select-field .select-field-container:hover .form-input-label,
.select-field .form-input-label.focus {
  color: var(--primary-color)
}

.select-field .form-input-label.shrink,
.select-field .message-label {
  font-size: 12px;
  line-height: 18px;
}

.select-field .form-input-label.shrink {
  transform: translateY(8px);
}

.select-field.error .form-input-label,
.select-field.error .message-label{
  color: var(--danger) !important; 
}

.select-field .message-label {
  margin: 0 0 0 16px;
  position: absolute;
  color: #767676;
}


.select-field .react-select__control {
  border: 1px solid #767676;
  padding: 18px 16px;
  border-radius: 8px;
  cursor: pointer;
}


.select-field .react-select__control:hover {
  border-color: var(--primary-color);
}

.select-field .react-select__control--is-focused {
  border: 1px solid var(--primary-color);
  box-shadow: none;
}

.select-field .react-select__control--is-focused:hover {
  border-color: var(--primary-color);
}


.select-field .react-select__value-container {
  padding: 0;
  position: relative;
  top: 7px;
}

.select-field .react-select__value-container > div {
  margin: 0px;
  padding: 0px;
}

.select-field .react-select__single-value {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin: 0;
}

.select-field .react-select__placeholder {
  display: none;
}

.select-field .react-select__input {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin: 0;
  padding: 0;
}

.select-field .react-select__indicator {
  padding: 0;
  color: #767676;
}

.select-field .react-select__indicator-separator {
  display: none;
}

.select-field .react-select__menu-list {
  max-height: 150px;
} 

.select-field .react-select__option {
  padding: 12px 12px;
}

.select-field .react-select__option--is-focused {
  background-color: #E6F4F0;
}

.select-field .react-select__option--is-selected {
  background-color: #2FA67F;
}

@media only screen and (max-width: 767px) {
  .select-field .react-select__control {
    padding: 16px 16px;
  }
  
  .select-field .react-select__input,
  .select-field .react-select__single-value {
    font-size: 16px;
    line-height: 18px;
  }

  .select-field .form-input-label {
    font-size: 16px;
    line-height: 24px;
    transform: translateY(18px);
    left: 16px;
  }

  .select-field .form-input-label.shrink {
    transform: translateY(6px);
  }
}

@media only screen and (max-width: 600px) {
  .select-field .react-select__control {
    padding: 11px 12px 11px 12px;
    border-radius: 4px;
  }

  .select-field .react-select__input,
  .select-field .react-select__single-value {
    font-size: 14px;
    line-height: 21px;
  }

  .select-field .form-input-label {
    font-size: 14px;
    line-height: 21px;
    transform: translateY(14px);
    left: 12px;
  }

  .select-field .form-input-label.shrink,
  .select-field .message-label {
    font-size: 10px;
    line-height: 15px;
  }

  .select-field .form-input-label.shrink {
    transform: translateY(4px);
  }

  .select-field .message-label {
    margin: 0 0 0 12px;
  }
}

/* ERROR */

.select-field.error .form-input-label,
.select-field.error .message-label{
  color: var(--danger) !important; 
}

.select-field.error .react-select__control {
  border: 1px solid var(--danger) !important;
}

/* DISABLED */

.select-field.disabled .form-input-label,
.select-field.disabled .message-label{
  color: var(--light-gray-text) !important; 
}

.select-field.disabled .react-select__control {
  border: 1px solid var(--light-gray-text) !important;
  background-color: #ffffff;
}


.radio-switch {
  display: flex;
}

.radio-switch .radio-switch-container {
  position: relative;
  width: 100%;
}

.radio-switch .radio-switch-container:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

.radio-switch .radio-switch-container:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.radio-switch label {
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
  background: #E6F4F0;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.radio-switch input[type=radio] {
  display: none;
}

.radio-switch input[type=radio]:checked + label {
  background: var(--primary-color);
  color: #FFFFFF;
}

@media only screen and (max-width: 767px) {
  .radio-switch label {
    font-size: 16px;
    line-height: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .radio-switch label {
    font-size: 14px;
    line-height: 21px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.referral-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.referral-preapproval .input-container {
  margin-top: 32px;
}

@media only screen and (max-width: 767px) {
  .referral-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .referral-preapproval .input-container {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .referral-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .referral-preapproval .input-container {
    margin-top: 16px;
  }
}
.toast {
	box-sizing: border-box;
	position: fixed;
  z-index: 999999;
  top: 0px;
  left: 0px;
}

.toast .toast-item {
	background: #FF5858;
  opacity: 0;
  display: none;
	pointer-events: auto;
	overflow: hidden;
	width: 100vw;
  color: #ffffff;
  padding: 16px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  box-sizing: border-box;
}

.toast .toast-item.success {
  background: var(--primary-color);
}

.toast span {
  font-weight: bold;
}

.toast .animate-enter {
  opacity: 0;
  display: block;
  transform: translateY(-150px);
}
.toast .animate-enter-active {
  opacity: 1;
  display: block;
  transform: translateY(0);
  transition: transform 500ms, opacity 300ms;
}
.toast .animate-enter-done {
  opacity: 1;
  display: block;
}
.toast .animate-exit {
  opacity: 1;
  display: block;
  transform: translateY(0);
}
.toast .animate-exit-active {
  opacity: 0;
  display: block;
  transform: translateY(-150px);
  transition: transform 500ms, opacity 300ms;
}

@media only screen and (max-width: 767px) {
  .toast .toast-item {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .toast .toast-item {
    font-size: 14px;
    line-height: 21px;
    padding: 8px;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(149, 149, 149, 0.58);
}

.modal-content {
  position: fixed;
  background-color: #ffffff;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  box-sizing: border-box;
}

/* --------------- MODAL FORM ---------------- */

.modal-form-container {
  padding: 32px;
  box-sizing: border-box;
  width: 600px;
}

.modal-form-container .modal-exit-button {
  width: 40px;
  height: 40px;
  margin-bottom: 32px;
  cursor: pointer;
}

.modal-form-container .modal-title {
  /* margin-top: 24px; */
  font-weight: bold;
  font-size: 40px;
  line-height: 58px;
  color: var(--black);
  margin-bottom: 32px;
}

.modal-form-container .modal-subtitle {
  font-size: 24px;
  line-height: 36px;
  color: var(--dark-gray-text);
  margin-bottom: 32px;
}

.modal-form-container .modal-input-container {
  margin-bottom: 24px;
}

.modal-form-container .modal-submit-button {
  width: 100%;
  height: 60px;
}

@media only screen and (max-width: 767px) {
  .modal-form-container {
    width: 481px;
  }

  .modal-form-container .modal-exit-button {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
  }

  .modal-form-container .modal-title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .modal-form-container .modal-subtitle {
    font-size: 20px;
    line-height: 30px;
  }

  .modal-form-container .modal-submit-button {
    font-size: 20px;
    line-height: 30px;
    height: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .modal-form-container {
    width: 288px;
    padding: 16px;
  }

  .modal-form-container .modal-exit-button {
    width: 24px;
    height: 24px;
    margin-bottom: 16px;
  }

  .modal-form-container .modal-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  .modal-form-container .modal-subtitle {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .modal-form-container .modal-input-container {
    margin-bottom: 16px;
  }

  .modal-form-container .modal-submit-button {
    font-size: 14px;
    line-height: 21px;
    height: 48px;
  }
}
.signup-preapproval .title {
  margin-bottom: 16px;
}

.signup-preapproval .sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: var(--dark-gray-text);
  margin-bottom: 48px;
}

.signup-preapproval .google-button {
  width: 100%;
  border: 1px solid #767676;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 24px;
  padding: 15px 0;
  height: auto;
}

.signup-preapproval .google-icon {
  margin-right: 16px;
  height: 24px;
  width: 24px;
}

.signup-preapproval .line-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.signup-preapproval .line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--light-gray-text);
}

.signup-preapproval .line + div {
  text-align: center;
  color: var(--light-gray-text);
  font-size: 20px;
  line-height: 21px;
  background-color: #ffffff;
  z-index: 3;
  padding: 0 16px;
}

.signup-preapproval .input-container:not(:first-child) {
  margin-top: 36px;
}

.signup-preapproval .input-container:nth-of-type(2) {
  margin-bottom: 64px;
}

.signup-preapproval .signup-button {
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  padding: 15px;
  height: auto;
}

.signup-preapproval .buttons {
  margin-bottom: 32px;
  margin-top: 48px;
}

.signup-preapproval .small-text {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--dark-gray-text);
  margin-bottom: 24px;
  cursor: pointer;
}

.signup-preapproval .small-text span {
  color: var(--primary-color);
  font-weight: bold;
}

.signup-preapproval .MuiCircularProgress-colorPrimary {
  color: #ffffff;
  width: 30px !important;
  height: 30px !important;
}

@media only screen and (max-width: 767px) {
  .signup-preapproval .title {
    margin-bottom: 12px;
  }

  .signup-preapproval .sub-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 48px;
  }

  .signup-preapproval .google-button {
    font-size: 20px;
    line-height: 24px;
    padding: 18px 0;
    height: auto;
  }
  
  .signup-preapproval .google-icon {
    margin-right: 12px;
  }

  .signup-preapproval .line + div {
    font-size: 16px;
    line-height: 21px;
  }

  .signup-preapproval .input-container:not(:first-child) {
    margin-top: 32px;
  }

  .signup-preapproval .input-container:nth-of-type(2) {
    margin-bottom: 64px;
  }

  .signup-preapproval .buttons {
    margin-top: 48px;
    margin-bottom: 24px;
  }

  .signup-preapproval .signup-button {
    font-size: 20px;
    line-height: 30px;
    padding: 15px;
  }
  
  .signup-preapproval .small-text {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .signup-preapproval .title {
    margin-bottom: 8px;
  }

  .signup-preapproval .sub-title {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .signup-preapproval .google-button {
    font-size: 14px;
    line-height: 21px;
    padding: 13px 0 13px 0;
    margin-bottom: 8px;
    height: auto;
    border-radius: 4px;
  }

  .signup-preapproval .google-icon {
    margin-right: 8px;
    height: 18px;
    width: 18px;
  }

  .signup-preapproval .line-container {
    margin-bottom: 8px;
  }

  .signup-preapproval .line + div {
    font-size: 14px;
    line-height: 21px;
    padding: 0 14px;
  }

  .signup-preapproval .input-container:not(:first-child) {
    margin-top: 24px;
  }

  .signup-preapproval .input-container:nth-of-type(2) {
    margin-bottom: 69px;
  }

  .signup-preapproval .checkbox-container {
    margin-left: 6px;
  }

  .signup-preapproval .buttons {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .signup-preapproval .signup-button {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    padding: 15px;
    height: auto;
    padding: 14px 0 13px 0;
  }
  
  .signup-preapproval .small-text {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }

  .signup-preapproval .MuiCircularProgress-colorPrimary {
    width: 21px !important;
    height: 21px !important;
  }
}
.property-preapproval .address-summary {
  border: 2px solid var(--primary-color);
  background: #F5FFFC;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 48px;
  display: flex;
}

.property-preapproval .green-marker {
  width: 32px;
  height: 32px;
}

.property-preapproval .address-summary-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--dark-gray-text);
  margin-left: 16px;
}

.property-preapproval .title {
  margin-bottom: 32px;
}

.property-preapproval .field-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 24px;
}

.property-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
}

.property-preapproval .fields-container {
  margin-top: 48px;
}

.property-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  .property-preapproval .address-summary {
    padding: 16px;
  }

  .property-preapproval .green-marker {
    width: 24px;
    height: 24px;
  }

  .property-preapproval .address-summary-text {
    font-size: 16px;
    line-height: 21px;
  }

  .property-preapproval .field-title {
    font-size: 20px;
    line-height: 30px;
  }

  .property-preapproval .fields-container {
    margin-top: 32px;
  }

  .property-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .property-preapproval .address-summary {
    border: 1px solid var(--primary-color);
    padding: 8px;
    margin-bottom: 24px;
  }

  .property-preapproval .green-marker {
    width: 20px;
    height: 20px;
  }

  .property-preapproval .address-summary-text {
    font-size: 12px;
    line-height: 18px;
    margin-left: 8px;
  }

  .property-preapproval .title {
    margin-bottom: 24px;
  }

  .property-preapproval .field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .property-preapproval .custom-radio-container {
    grid-template-columns: 1fr;
    grid-gap: 8px;
    gap: 8px;
  }

  .property-preapproval .fields-container {
    margin-top: 24px;
  }

  .property-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  position: relative;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox .checkmark {
  border: 2px solid #2FA67F;
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.checkbox input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 8px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox .checkbox-label {
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin-left: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .checkbox .checkmark {
    width: 20px;
    height: 20px;
  }

  .checkbox .checkmark:after {
    left: 7.5px;
    top: 6px;
    width: 3px;
    height: 8px;
    border-width: 0 2px 2px 0;
  }

  .checkbox .checkbox-label {
    font-size: 16px;
    line-height: 24px;
    margin-left: 12px;
  }
}
.contact-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

.contact-preapproval .MuiCircularProgress-colorPrimary {
  color: #ffffff;
  width: 30px !important;
  height: 30px !important;
}

@media only screen and (max-width: 767px) {
  .contact-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .contact-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }

  .contact-preapproval .MuiCircularProgress-colorPrimary {
    width: 21px !important;
    height: 21px !important;
  }
}
.income-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.income-preapproval .input-container {
  margin-top: 24px;
}

@media only screen and (max-width: 767px) {
  .income-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .income-preapproval .input-container {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .income-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .income-preapproval .input-container {
    margin-top: 16px;
  }
}
.monthly-preapproval .payment-container {
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .monthly-preapproval .payment-container {
    margin-top: 72px;
  }
}

@media only screen and (max-width: 600px) {
  .monthly-preapproval .payment-container {
    margin-top: 56px;
  }
}


/* NORMAL STATE */

.price-field {
  position: relative;
  box-sizing: border-box;
}

.price-field .calc-field {
  padding: 18px 16px 18px 52px;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  border: 1px solid #767676;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.price-field .adornment {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  font-size: 20px;
  line-height: 30px;
  color: #767676;
}

.price-field .message {
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
  left: 16px;
  top: 68px;
}

/* ACTIVE STATE */

.price-field .calc-field:focus,
.price-field .calc-field:hover {
  border: 1px solid var(--primary-color);
}

.price-field .calc-field:focus + .adornment,
.price-field .calc-field:hover + .adornment {
  color: var(--primary-color);
}

/* DISABLED STATE */

.price-field .calc-field:disabled {
  background-color: #ffffff;
  border: 1px solid var(--light-gray);
}

.price-field .calc-field:disabled + .adornment{
  color: var(--light-gray) !important;
}

/* ERROR STATE */

.price-field.error .calc-field {
  border: 1px solid var(--danger);
}

.price-field.error .adornment {
  color: var(--danger) !important;
}

.price-field.error .message {
  color: var(--danger)
}

@media only screen and (max-width: 767px) {
  .price-field .calc-field {
    padding: 17px 16px 17px 47px;
    font-size: 16px;
    line-height: 24px;
  }

  .price-field .adornment {
    font-size: 16px;
    line-height: 24px;
  }

  .price-field .message {
    font-size: 12px;
    line-height: 18px;
    top: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .price-field .calc-field {
    padding: 12px 12px 13px 37px;
    font-size: 14px;
    line-height: 21px;
    border-radius: 4px;
  }

  .price-field .adornment {
    font-size: 14px;
    line-height: 21px;
    left: 12px;
  }

  .price-field .message {
    font-size: 10px;
    line-height: 15px;
    top: 48px;
    left: 12px;
  }
}

.textarea-custom {
  position: relative;
}

.textarea-custom textarea::-webkit-input-placeholder {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.textarea-custom textarea:-ms-input-placeholder {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.textarea-custom textarea::-ms-input-placeholder {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.textarea-custom textarea,
.textarea-custom textarea::placeholder {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.textarea-custom textarea {
  width: 100%;
  resize: none;
  padding: 18px 16px;
  border: 1px solid #767676;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--black);
  background-color: white;
}

.textarea-custom .message {
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
  top: calc(100% - 3px);
  left: 16px;
}

.textarea-custom textarea::-webkit-input-placeholder {
  color: #767676;
}

.textarea-custom textarea:-ms-input-placeholder {
  color: #767676;
}

.textarea-custom textarea::-ms-input-placeholder {
  color: #767676;
}

.textarea-custom textarea::placeholder {
  color: #767676;
}

.textarea-custom textarea:hover,
.textarea-custom textarea:focus {
  border-color: var(--primary-color);
}

.textarea-custom textarea:hover::-webkit-input-placeholder {
  color: var(--primary-color);
}

.textarea-custom textarea:hover:-ms-input-placeholder {
  color: var(--primary-color);
}

.textarea-custom textarea:hover::-ms-input-placeholder {
  color: var(--primary-color);
}

.textarea-custom textarea:hover::placeholder {
  color: var(--primary-color);
}

/* DISABLED STATE */

.textarea-custom textarea:disabled {
  border: 1px solid var(--light-gray);
  background-color: white;
}

.textarea-custom textarea:disabled::-webkit-input-placeholder {
  color: var(--light-gray);
}

.textarea-custom textarea:disabled:-ms-input-placeholder {
  color: var(--light-gray);
}

.textarea-custom textarea:disabled::-ms-input-placeholder {
  color: var(--light-gray);
}

.textarea-custom textarea:disabled::placeholder {
  color: var(--light-gray);
}

/* ERROR STATE */

.textarea-custom.error textarea {
  border: 1px solid var(--danger);
}

.textarea-custom.error textarea::-webkit-input-placeholder {
  color: var(--danger);
}

.textarea-custom.error textarea:-ms-input-placeholder {
  color: var(--danger);
}

.textarea-custom.error textarea::-ms-input-placeholder {
  color: var(--danger);
}

.textarea-custom.error textarea::placeholder {
  color: var(--danger);
}

.textarea-custom.error .message {
  color: var(--danger);
}

@media only screen and (max-width: 767px) {
  .textarea-custom textarea {
    padding: 17px 16px;
  }

  .textarea-custom textarea::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  .textarea-custom textarea:-ms-input-placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  .textarea-custom textarea::-ms-input-placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  .textarea-custom textarea,
  .textarea-custom textarea::placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  .textarea-custom .message {
    font-size: 12px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .textarea-custom textarea {
    padding: 13px 12px 12px 12px;
    border-radius: 4px;
  }

  .textarea-custom textarea::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 21px;
  }

  .textarea-custom textarea:-ms-input-placeholder {
    font-size: 14px;
    line-height: 21px;
  }

  .textarea-custom textarea::-ms-input-placeholder {
    font-size: 14px;
    line-height: 21px;
  }

  .textarea-custom textarea,
  .textarea-custom textarea::placeholder {
    font-size: 14px;
    line-height: 21px;
  }

  .textarea-custom .message {
    font-size: 10px;
    line-height: 15px;
    left: 12px;
  }
}

.timeline-preapproval .custom-radio-container div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .timeline-preapproval .custom-radio-container div:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .timeline-preapproval .custom-radio-container div:not(:last-child) {
    margin-bottom: 8px;
  }
}

.mortgage-preapproval .date-container {
  margin-top: 80px;
}

.mortgage-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
}

.mortgage-preapproval .buttons {
  margin-bottom: 94px;
}

@media only screen and (max-width: 767px) {
  .mortgage-preapproval .date-container {
    margin-top: 72px;
  }

  .mortgage-preapproval .col-2 {
    grid-gap: 16px;
    gap: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .mortgage-preapproval .date-container {
    margin-top: 56px;
  }

  .mortgage-preapproval .col-2 {
    grid-gap: 16px;
    gap: 16px;
  }
}
.result-preapproval .custom-radio-container div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .result-preapproval .custom-radio-container div:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .result-preapproval .custom-radio-container div:not(:last-child) {
    margin-bottom: 8px;
  }
}
.result-reason-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.result-reason-preapproval .input-container {
  margin-top: 24px;
}

@media only screen and (max-width: 767px) {
  .result-reason-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .result-reason-preapproval .input-container {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .result-reason-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .result-reason-preapproval .input-container {
    margin-top: 16px;
  }
}
.listing-preapproval .price-container {
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .listing-preapproval .price-container {
    margin-top: 72px;
  }
}

@media only screen and (max-width: 600px) {
  .listing-preapproval .price-container {
    margin-top: 56px;
  }
}


.purchase-preapproval .date-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
}

.purchase-preapproval .buttons {
  margin-bottom: 94px;
}

@media only screen and (max-width: 767px) {
  .purchase-preapproval .date-container {
    grid-gap: 16px;
    gap: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .purchase-preapproval .date-container {
    grid-gap: 16px;
    gap: 16px;
  }
}

.buyback-preapproval .title {
  margin-bottom: 16px
}

.buyback-preapproval .sub-title {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 32px;
  color: var(--dark-gray-text);
}

@media only screen and (max-width: 767px) {
  .buyback-preapproval .title {
    margin-bottom: 12px
  }

  .buyback-preapproval .sub-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .buyback-preapproval .title {
    margin-bottom: 8px
  }

  .buyback-preapproval .sub-title {
    font-size: 14px;
    line-height: 21px;
  }
}
.simple-list-group > div {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 28px;
}

.simple-list-group > div:not(:first-child) {
  margin-top: 28px;
}

@media only screen and (max-width: 767px) {
  .simple-list-group > div {
    padding-bottom: 24px;
  }

  .simple-list-group > div:not(:first-child) {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .simple-list-group > div {
    padding-bottom: 16px;
  }

  .simple-list-group > div:not(:first-child) {
    margin-top: 16px;
  }
}
.simple-property-item .tag {
  font-size: 12px;
  line-height: 15px;
  color: #2FA67F;
  background-color: #F0FFFA;
  padding: 8px;
  display: inline-block;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.simple-property-item .address {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: var(--black);
}

@media only screen and (max-width: 767px) {
  .simple-property-item .tag {
    font-size: 12px;
    line-height: 18px;
    padding: 2px 4px
  }

  .simple-property-item .address {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}

@media only screen and (max-width: 600px) {
  .simple-property-item .tag {
    font-size: 10px;
    line-height: 15px;
  }

  .simple-property-item .address {
    font-size: 14px;
    line-height: 21px;
  }

}


.parking-details-preapproval .checkbox-container {
  margin-top: 28px;
}

.parking-details-preapproval .checkbox-label {
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .parking-details-preapproval .title {
    margin-bottom: 40px;
  }

  .parking-details-preapproval .checkbox-container {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .parking-details-preapproval .checkbox-container {
    margin-top: 16px;
  }

  .parking-details-preapproval .title {
    margin-bottom: 24px;
  }
}
.simple-parking-type-item {
  display: flex;
  position: relative;
}

.simple-parking-type-item input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.simple-parking-type-item .checkmark {
  border: 2px solid #2FA67F;
  box-sizing: border-box;
  border-radius: 4px;
  width: 25.7px;
  height: 24px;
  cursor: pointer;
}

.simple-parking-type-item input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

.simple-parking-type-item .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.simple-parking-type-item input:checked ~ .checkmark:after {
  display: block;
}

.simple-parking-type-item .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.simple-parking-type-item .details-container {
  margin-left: 24px;
  display: flex;
  width: 100%;
}

.simple-parking-type-item .image {
  width: 94px;
  height: 94px;
}

.simple-parking-type-item .details {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.simple-parking-type-item .parking-type-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
}

.simple-parking-type-item .parking-type-sub-title {
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-gray-text);
  margin-bottom: 16px;
}

.simple-parking-type-item .buttons-container {
  display: flex;
  align-items: center;
}

.simple-parking-type-item .button {
  height: auto;
  width: auto;
  min-width: 0px;
  padding: 3px;
  border-radius: 4px;
  margin: 0px;
}

.simple-parking-type-item .button.Mui-disabled {
  color: var(--light-gray);
  border: 1px solid var(--light-gray);
}

.simple-parking-type-item .MuiButton-label {
  width: 16px;
  height: 16px;
}

.simple-parking-type-item .button-icon {
  width: 16px;
  height: 16px;
  fill: var(--light-gray);
  transition: fill ease-in-out 0.1s;
}

.simple-parking-type-item .button-icon.checked {
  fill: var(--primary-color);
}

.simple-parking-type-item .capacity {
  font-size: 16px;
  line-height: 24px;
  margin: 0 16px;
  color: var(--black);
}

.simple-parking-type-item .capacity.disabled {
  color: var(--light-gray);
}

@media only screen and (max-width: 767px) {
  .simple-parking-type-item .image {
    width: 104px;
    height: 104px;
  }

  .simple-parking-type-item .details {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .simple-parking-type-item .checkmark {
    width: 21.6px;
    height: 20px;
  }

  .simple-parking-type-item .checkmark:after {
    left: 7.5px;
    top: 4px;
    width: 3px;
    height: 8px;
    border-width: 0 2px 2px 0;
  }

  .simple-parking-type-item .details-container {
    margin-left: 12px;
  }

  .simple-parking-type-item .image {
    width: 77px;
    height: 77px;
  }

  .simple-parking-type-item .details {
    margin-left: 16px;
  }

  .simple-parking-type-item .parking-type-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .simple-parking-type-item .parking-type-sub-title {
    font-size: 12px;
    line-height: 18px;
  }

  .simple-parking-type-item .MuiButton-label {
    width: 12px;
    height: 12px;
  }
  
  .simple-parking-type-item .button-icon {
    width: 12px;
    height: 12px;
  }
  
  .simple-parking-type-item .capacity {
    font-size: 12px;
    line-height: 18px;
    margin: 0 12px;
  }
  
}
.financial-preapproval .title {
  margin-bottom: 16px;
}

.financial-preapproval .sub-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--dark-gray-text);
  margin-bottom: 40px;
}

.financial-preapproval .price-field,
.financial-preapproval .textarea-custom {
  margin-top: 20px;
  margin-left: 48px;
}

.financial-preapproval .checkbox-label {
  font-weight: bold;
}

.financial-preapproval .textarea-custom + .price-field {
  margin-top: 36px;
}

.financial-preapproval .total-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-top: 28px;
  margin-left: 48px;
  color: var(--black);
}

.financial-preapproval .buttons {
  margin-bottom: 48px;
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .financial-preapproval .title {
    margin-bottom: 12px;
  }

  .financial-preapproval .sub-title {
    font-size: 16px;
    line-height: 24px;
  }

  .financial-preapproval .textarea-custom + .price-field {
    margin-top: 32px;
  } 

  .financial-preapproval .total-label {
    margin-top: 24px;
  }

  .financial-preapproval .buttons {
    margin-top: 72px;
  }
}

@media only screen and (max-width: 600px) {
  .financial-preapproval .title {
    margin-bottom: 16px;
  }

  .financial-preapproval .sub-title {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 30px;
  }

  .financial-preapproval .price-field,
  .financial-preapproval .textarea-custom {
    margin-top: 12px;
    margin-left: 32px;
  }

  .financial-preapproval .textarea-custom + .price-field {
    margin-top: 24px;
  }

  .financial-preapproval .total-label {
    margin-top: 16px;
    margin-left: 32px;
    font-size: 20px;
    line-height: 36px;
  }

  .financial-preapproval .buttons {
    margin-top: 56px;
  }
}
.language-switcher {
	display: flex;
	background: white;
	padding: 2px;
	width: 92px;
	box-sizing: border-box;
	border-radius: 4px;
}

.language-switcher .language-switcher-container {
	position: relative;
}

.language-switcher .language-switcher-container {
	border-radius: 4px;
}

.language-switcher label {
	display: inline-block;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: var(--primary-color);
	cursor: pointer;
	padding: 4px 0px;
	width: 44px;
	box-sizing: border-box;
	background: white;
	text-align: center;
	border-radius: 4px;
	transition: background 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
		color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.language-switcher label.active {
	background: var(--primary-color);
	color: #ffffff;
}

.side-bar {
	position: fixed;
}

.side-bar {
	background-color: var(--primary-color);
	width: 243px;
	height: 100%;
}

.side-bar .logo-container {
	margin: 25px 32px 32px;
	cursor: pointer;
	width: 122px;
	height: 24px;
}

.side-bar .logo {
	height: 100%;
	width: 100%;
}

.side-bar .profile-container {
	margin: 0px 22px 16px 32px;
	display: flex;
	align-items: center;
}

.side-bar .user-icon-container {
	margin-right: 12px;
	display: flex;
}

.side-bar .user-details-container {
	color: white;
	max-width: 189px;
}

.side-bar .full-name {
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 700;
}

.side-bar .email {
	font-size: 12px;
	overflow-wrap: anywhere;
}

.side-bar .switch-wrapper {
	position: absolute;
	bottom: 16px;
	right: 16px;
}

.side-bar .side-menu .hr {
	width: 100%;
}

.side-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.side-menu > ul > li a {
	display: block;
	padding: 16px 16px 16px 32px;
}

.side-menu > ul > li.active {
	background-color: #33b389;
}

.side-menu .sub-menu > ul > li a {
	padding-top: 16px;
	padding-bottom: 0px;
}

.side-menu .sub-menu > ul > li:last-child a {
	padding-bottom: 16px;
}

.side-menu ul li,
.side-menu ul li a {
	cursor: pointer;
	color: white;
	font-size: 16px;
	font-weight: 700;
}

.side-menu .mobile-menu-list {
	display: flex;
	justify-content: space-between;
	padding: 16px 16px 16px 32px;
	align-items: center;
}

.side-menu .menu-list-arrow {
	position: relative;
}

.side-menu .sub-menu {
	padding-left: 16px;
}

.side-menu .sub-menu ul li a {
	font-weight: 400;
}

.side-menu .sub-menu ul li:first-child a {
	padding-top: 0px;
}

.side-menu .sub-menu ul li:last-child {
	padding-bottom: 0px;
}

.side-menu .menu-list-arrow {
	transition: 0.1s ease-in-out;
}

.side-menu .menu-list-arrow.open {
	transform: rotate(180deg);
}

.side-menu .auth:nth-child(2) {
	margin-top: 8px;
}

.side-menu .auth {
	padding: 16px 32px 16px;
	color: white;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	display: block
}

.side-menu .hr {
	margin: 0 auto;
	margin-top: 16px;
	width: 232px;
}

@media only screen and (max-width: 767px) {
	.side-menu > ul > li a,
	.side-menu .mobile-menu-list {
		padding: 12px 16px;
	}

	.side-menu .sub-menu > ul > li a {
		padding-top: 12px;
		padding-bottom: 0px;
	}
	
	.side-menu .sub-menu > ul > li:last-child a {
		padding-bottom: 12px;
	}

	.side-menu .auth:nth-child(2) {
		margin-top: 12px;
	}

	.side-menu .auth {
		padding: 12px 16px;
	}

	.side-menu .hr {
		margin-top: 12px;
		width: 208px;
	}
}

.overview .overview-container {
	width: 620px;
}

@media only screen and (max-width: 1080px) {
	.overview .overview-container {
		width: 460px;
	}
}

@media only screen and (max-width: 767px) {
	.overview .overview-container {
		width: 100%;
	}
}

.overview-header {
	font-weight: 900;
	margin-bottom: 80px;
}

.overview-header .sub-header {
	color: var(--black);
	text-transform: uppercase;
	margin-bottom: 8px;
	font-size: 16px;
	line-height: 24px;
}

.overview-header .header {
	font-size: 56px;
	line-height: 68px;
}

@media only screen and (max-width: 1080px) {
	.overview-header {
		margin-bottom: 64px;
	}

	.overview-header .sub-header {
		margin-bottom: 4px;
	}

	.overview-header .header {
		font-size: 44px;
		line-height: 57px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-header {
		margin-bottom: 32px;
	}

	.overview-header .sub-header {
		font-size: 12px;
		margin-bottom: 0px;
	}

	.overview-header .header {
		font-size: 24px;
		line-height: 36px;
	}
}

.overview-progress {
	display: flex;
	justify-content: space-between;
	height: 132px;
	margin-bottom: 56px;
}

.overview-progress .progress {
	position: relative;
}

.overview-progress .circle {
	background-color: var(--light-green);
	width: 48px;
	height: 48px;
	border-radius: 25px;
}

.overview-progress .sign,
.overview-progress .check {
	text-align: center;
	line-height: 48px;
	font-size: 24px;
	font-weight: 700;
	color: var(--primary-color);
}

.overview-progress .check {
	position: relative;
	top: 2px;
}

.overview-progress .grow {
	flex-basis: 33%;
}

.overview-progress .bar {
	height: 12px;
	width: 100%;
	background-color: var(--light-green);
	position: absolute;
	top: 18px;
	left: 24px;
	z-index: -1;
}

.overview-progress .check {
	color: white;
}

.overview-progress .progress.active .circle,
.overview-progress .progress.active .bar {
	background-color: var(--primary-color);
}

.overview-progress .content {
	margin-top: 24px;
	color: var(--primary-color);
	font-size: 20px;
	line-height: 30px;
	max-width: 114px;
	position: absolute;
}

.overview-progress .verified .content {
	left: -24px;
}

.overview-progress .approved .content {
	right: 0px;
	text-align: right;
}

@media only screen and (max-width: 1080px) {
	.overview-progress {
		margin-bottom: 64px;
		height: 94px;
	}

	.overview-progress .circle {
		width: 32px;
		height: 32px;
	}

	.overview-progress .sign,
	.overview-progress .check {
		line-height: 32px;
		font-size: 16px;
	}

	.overview-progress .bar {
		height: 6px;
		top: 14px;
		left: 16px;
	}

	.overview-progress .content {
		margin-top: 16px;
		color: var(--primary-color);
		font-size: 16px;
		line-height: 24px;
		max-width: 90px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-progress {
		height: 66px;
		margin-bottom: 32px;
	}

	.overview-progress .circle {
		width: 24px;
		height: 24px;
	}

	.overview-progress .sign,
	.overview-progress .check {
		line-height: 24px;
		font-size: 12px;
	}

	.overview-progress .bar {
		height: 4px;
		top: 10px;
		left: 12px;
	}

	.overview-progress .content {
		margin-top: 11px;
		font-size: 12px;
		line-height: 15px;
		max-width: 68px;
	}

	.overview-progress .verified .content {
		left: -18px;
	}
}

.overview-tasks {
	border-radius: 8px;
	border: 1px solid var(--light-gray);
	padding: 24px 32px;
	margin-bottom: 40px;
}

.overview-tasks .title {
	font-size: 24px;
	line-height: 36px;
	font-weight: 700;
	color: var(--black);
	margin-bottom: 16px;
}

.overview-tasks .description {
	font-size: 20px;
	line-height: 30px;
	color: var(--dark-gray-text);
	margin-bottom: 48px;
}

.overview-tasks .button.MuiButton-contained {
	font-size: 16px;
	padding: 12px 26px;
	background-color: var(--primary-color);
	height: 48px;
}

@media only screen and (max-width: 1080px) {
	.overview-tasks {
		padding: 16px 20px;
		margin-bottom: 24px;
	}

	.overview-tasks .description {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-tasks {
		padding: 16px 16px;
		margin-bottom: 16px;
	}

	.overview-tasks .title {
		font-size: 20px;
		line-height: 26px;
	}

	.overview-tasks .description {
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 32px;
	}

	.overview-tasks .button.MuiButton-contained {
		font-size: 16px;
		padding: 10px 24px;
		height: 43px;
	}
}

@media only screen and (max-width: 480px) {
	.overview-tasks .button.MuiButton-contained {
		width: 100%;
	}
}

.overview-property {
	border-radius: 8px;
	border: 1px solid var(--light-gray);
	padding: 24px 32px;
}

.overview-property .title {
	font-size: 24px;
	line-height: 36px;
	font-weight: 700;
	color: var(--black);
	margin-bottom: 16px;
}

.overview-property .images-container {
	border-radius: 8px;
	overflow: hidden;
	margin-bottom: 24px;
	mask-image: radial-gradient(white, black);
	-webkit-mask-image: -webkit-radial-gradient(white, black);
}

.overview-property .carousel img {
	height: 311px;
	object-fit: cover;
}

.overview-property .carousel.carousel-slider .control-arrow {
	background: rgba(0, 0, 0, 0.3);
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
	opacity: 1;
}

.overview-property .upload-container {
	height: 311px;
	background-color: var(--light-green);
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
}

.overview-property .content {
	margin: auto;
	text-align: center;
}

.overview-property .upload-description {
	font-size: 16px;
	margin-bottom: 8px;
}

.overview-property .upload-link {
	font-size: 16px;
	color: var(--primary-color);
	cursor: pointer;
}

.overview-property .property-details-grid {
	display: grid;
	grid-template-columns: 50% 50%;
}

.overview-property .detail.address {
	grid-column-start: 1;
	grid-column-end: 3;
}

.overview-property .detail:not(:last-child) {
	margin-bottom: 32px;
}

.overview-property .detail-title,
.overview-property .detail-value {
	font-size: 20px;
	line-height: 30px;
}

.overview-property .detail-title {
	color: var(--dark-gray-text);
	margin-bottom: 4px;
}

.overview-property .detail-value {
	color: var(--black);
	font-weight: 700;
}

@media only screen and (max-width: 1080px) {
	.overview-property {
		padding: 16px 20px;
	}

	.overview-property .title {
		margin-bottom: 24px;
	}

	.overview-property .carousel img {
		height: 210px;
	}

	.overview-property .upload-container {
		height: 210px;
	}

	.overview-property .detail-title,
	.overview-property .detail-value {
		font-size: 14px;
		line-height: 21px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-property {
		padding: 16px 16px;
	}

	.overview-property .title {
		font-size: 20px;
		line-height: 26px;
	}

	.overview-property .carousel img {
		height: 180px;
	}

	.overview-property .upload-container {
		height: 180px;
	}

	.overview-property .upload-description {
		font-size: 12px;
		line-height: 18px;
	}

	.overview-property .upload-link {
		font-size: 12px;
		line-height: 18px;
	}
}

.dashboard {
	display: flex;
	height: 100%;
}

.dashboard .app-bar,
.dashboard .offset {
	display: none;
	width: 100%;
}

.dashboard .main-section {
	padding: 48px 104px 48px 347px;
}
@media only screen and (max-width: 1080px) {
	.dashboard .main-section {
		padding: 32px 104px 32px 275px;
	}
}

@media only screen and (max-width: 767px) {
	.dashboard {
		display: block;
	}
	.dashboard .app-bar,
	.dashboard .offset {
		display: block;
	}

	.dashboard .side-bar {
		display: none;
	}

	.dashboard .main-section {
		padding: 32px;
	}
}

@media only screen and (max-width: 600px) {
	.dashboard .main-section {
		padding: 16px;
	}
}

.alert {
	padding: 16px;
	border-radius: 5px;
	border: 1px solid;
	display: flex;
	margin-bottom: 48px;
}

.alert .email {
	font-weight: 900;
}

.alert .icon-container {
	padding-right: 18px;
}

.alert .icon-container svg {
	font-size: 20px;
	height: 30px;
}

.alert .text {
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 24px;
}

.alert .button.MuiButton-contained {
	font-size: 20px;
	padding: 12px 32px;
	float: right;
	height: 54px;
}

@media only screen and (max-width: 1080px) {
	.alert {
		margin-bottom: 32px;
	}

	.alert .text {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 16px;
	}

	.alert .icon-container {
		padding-right: 16px;
	}

	.alert .icon-container svg {
		font-size: 16px;
		height: 24px;
	}

	.alert .button.MuiButton-contained {
		font-size: 16px;
		height: 48px;
	}
}

@media only screen and (max-width: 600px) {
	.alert {
		padding: 12px;
		margin-bottom: 24px;
	}

	.alert .text {
		font-size: 12px;
		line-height: 18px;
	}

	.alert .icon-container {
		padding-right: 12px;
	}

	.alert .icon-container svg {
		font-size: 16px;
		height: 18px;
	}

	.alert .button.MuiButton-contained {
		font-size: 12px;
		height: 34px;
		padding: 8px 15px;
	}
}

.email-reminder.warning {
	color: #b88218;
	border-color: #f6d089;
	background-color: #fff6ed;
}

.email-reminder.warning .button.MuiButton-contained {
	background-color: #b88218;
}

.email-reminder.danger {
	color: #D65B5B;
	border-color: #FF5858;
	background-color: #FFECEC;
}

.email-reminder.danger .button.MuiButton-contained {
	background-color: #FF5858;
}

.is-private {
	color: var(--primary-color);
	background-color: #f8fefc;
	border-color: #2fa67f;
}

.is-private .text {
	font-weight: 700;
	margin-bottom: 0px;
}

.is-private .icon-container {
	position: relative;
	top: 6px;
}

.is-private .icon-container svg {
	height: 20px;
}

.is-private {
	margin-bottom: 40px;
}

@media only screen and (max-width: 1080px) {
  .is-private .icon-container {
    top: 2px;
  }
}

@media only screen and (max-width: 600px) {
  .is-private .icon-container {
    top: 0px;
  }
}
.summary .summary-container {
	width: 620px;
}

@media only screen and (max-width: 1080px) {
	.summary .summary-container {
		width: 460px;
	}
}

@media only screen and (max-width: 767px) {
	.summary .summary-container {
		width: 100%;
	}
}

.summary-header {
	font-weight: 900;
	margin-bottom: 40px;
}

.summary-header .sub-header {
	color: var(--black);
	text-transform: uppercase;
	margin-bottom: 8px;
	font-size: 16px;
	line-height: 24px;
}

.summary-header .header {
	font-size: 56px;
	line-height: 68px;
	margin-bottom: 24px;
}

.summary-header .description {
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
	color: var(--dark-gray-text);
}

@media only screen and (max-width: 1080px) {
	.summary-header {
		margin-bottom: 32px;
		font-size: 44px;
		line-height: 57px;
	}

	.summary-header .sub-header {
		margin-bottom: 4px;
	}

	.summary-header .header {
		font-size: 44px;
		line-height: 57px;
	}

	.summary-header .description {
		font-size: 16px;
		line-height: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-header {
		margin-bottom: 24px;
	}

	.summary-header .sub-header {
		font-size: 12px;
		margin-bottom: 0px;
	}

	.summary-header .header {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 12px;
	}
}

.summary-panel {
	border-radius: 8px;
	border: 1px solid var(--light-gray);
	margin-bottom: 32px;
}

@media only screen and (max-width: 1080px) {
	.summary-panel {
		margin-bottom: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-panel {
		margin-bottom: 16px;
	}
}

.summary-panel-header {
	padding: 24px 32px;
	border-bottom: 1px solid var(--light-gray);
	display: flex;
}

.summary-panel-header .icon-container {
	padding-right: 24px;
}

.summary-panel-header .title {
	color: var(--black);
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	margin-bottom: 4px;
}

.summary-panel-header .description {
	color: var(--dark-gray-text);
	font-size: 20px;
}

@media only screen and (max-width: 1080px) {
	.summary-panel-header {
		padding: 20px;
	}

	.summary-panel-header .icon-container {
		padding-right: 16px;
	}

	.summary-panel-header .icon-container img {
		width: 60px;
	}

	.summary-panel-header .title {
		font-size: 20px;
	}

	.summary-panel-header .description {
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-panel-header {
		padding: 16px;
	}

	.summary-panel-header .icon-container {
		padding-right: 12px;
	}

	.summary-panel-header .icon-container img {
		width: 48px;
	}

	.summary-panel-header .title {
		font-size: 16px;
	}

	.summary-panel-header .description {
		font-size: 12px;
	}
}

.summary-panel-content .fields {
	display: grid;
	grid-template-columns: 5fr 5fr;
	padding: 32px;
	grid-gap: 24px;
	overflow-wrap: anywhere;
}

.summary-panel-content .field {
	overflow-wrap: anywhere;
}

.summary-panel-content .field-title {
	color: var(--dark-gray-text);
	font-size: 20px;
	margin-bottom: 4px;
}

.summary-panel-content .field-value {
	font-size: 20px;
	color: var(--black);
	font-weight: 700;
}

@media only screen and (max-width: 1080px) {
	.summary-panel-content .fields {
		padding: 20px;
		grid-gap: 18px;
	}

	.summary-panel-content .field-title {
		font-size: 14px;
	}

	.summary-panel-content .field-value {
		font-size: 16px;
	}
}

@media only screen and (max-width: 767px) {
	.summary-panel-content .field-value {
		font-size: 14px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-panel-content .fields {
		padding: 16px;
		grid-gap: 16px;
	}
}

@media only screen and (max-width: 374px) {
	.summary-panel-content .fields {
		grid-template-columns: 100%;
	}
}

.summary-panel-toggle {
	padding: 18px;
	border-top: 1px solid var(--light-gray);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.summary-panel-toggle .toggle {
	font-size: 24px;
	color: var(--primary-color);
	padding-right: 10px;
	font-weight: 700;
}

.summary-panel-toggle svg {
	color: var(--primary-color);
	font-size: 30px;
}

@media only screen and (max-width: 1080px) {
	.summary-panel-toggle .toggle {
		font-size: 16px;
		padding-right: 5px;
	}

	.summary-panel-toggle svg {
		font-size: 20px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-panel-toggle .toggle {
		font-size: 14px;
	}

	.summary-panel-toggle svg {
		font-size: 17px;
	}
}

.summary-property .title {
	font-size: 12px;
	display: inline-block;
	background-color: #f0fffa;
	text-transform: uppercase;
	color: var(--primary-color);
	font-weight: 400;
	padding: 4px 8px;
	border-radius: 4px;
	margin-bottom: 0;
}

.summary-property .description {
	color: var(--black);
	font-size: 24px;
	font-weight: 700;
}

.summary-property .field:first-child {
	grid-column-start: 1;
	grid-column-end: 3;
}

@media only screen and (max-width: 1080px) {
	.summary-property .description {
		font-size: 20px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-property .title {
		font-size: 10px;
	}

	.summary-property .description {
		font-size: 16px;
	}
}

@media only screen and (max-width: 374px) {
	.summary-property .fields {
		grid-template-columns: 1fr 1fr;
	}

	.summary-property .field:first-child {
		grid-column-start: 1;
		grid-column-end: 3;
	}
}

.error-preapproval .error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  
}

.error-preapproval .error-image {
  margin-bottom: 40px;
  width: 225px;
  height: 196px;
}

.error-preapproval .title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  display: inline-block;
  width: auto;
  color: var(--black);
  margin-bottom: 24px;
}

.error-preapproval .description {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: var(--dark-gray-text);
  width: 368px;
  margin-bottom: 56px;
}

.error-preapproval .button {
  width: 207px;
  font-size: 20px;
  margin-right: 0px;
}

@media only screen and (max-width: 767px) {
  .error-preapproval .error-container {
    width: 100vw;
    margin-top: 0px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
  
  .error-preapproval .error-image {
    margin-bottom: 56px;
    width: 280px;
    height: 243px;
  }
  
  .error-preapproval .title {
    margin-bottom: 24px;
  }
  
  .error-preapproval .description {
    margin-bottom: 56px;
  }

  .error-preapproval .button {
    height: 60px
  }
}

@media only screen and (max-width: 600px) {
  .error-preapproval .error-image {
    margin-bottom: 40px;
    width: 160px;
    height: 141px;
  }
  
  .error-preapproval .title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
  
  .error-preapproval .description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
    width: 272px;
  }

  .error-preapproval .button {
    width: 136px;
    height: 48px;
    font-size: 14px;
  }
}
.verification .verification-container {
	padding: 120px 16px 0px 16px;
}

.verification .modal-overlay {
	background: rgba(255, 255, 255, 0.6);
	-webkit-backdrop-filter: blur(32px);
	        backdrop-filter: blur(32px);
}

@media only screen and (max-width: 1080px) {
	.verification: {
		padding: 80px 175px 0px 175px;
	}

	.verification .resend,
	.verification .countdown {
		margin-bottom: 72px;
		font-size: 20px;
	}

	.verification .verification-input .input {
		width: 408px !important;
		margin-bottom: 134px;
	}

	.verification .input input {
		width: 48px !important;
		margin-right: 24px;
	}

	.verification .submit-button {
		width: 417px;
		height: 60px;
		margin-bottom: 20px;
	}

	.verification .change-email {
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.verification: {
		padding: 40px 16px 0px 16px;
	}

	.verification .resend,
	.verification .countdown {
		margin-bottom: 78px;
		font-size: 14px;
	}

	.verification .verification-input .input {
		width: 272px !important;
		margin-bottom: 85px;
	}

	.verification .input input {
		width: 32px !important;
		margin-right: 16px;
		font-size: 20px;
	}

	.verification .submit-button {
		width: 100%;
		height: 48px;
		max-width: 288px;
		font-size: 14px;
	}

	.verification .change-email {
		font-size: 12px;
	}
}

.verification-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.verification-header .profile-pic {
	margin-bottom: 48px;
}

.verification-header .title {
	font-size: 40px;
	line-height: 58px;
	color: var(--black);
	margin-bottom: 16px;
}

.verification-header .description {
	font-size: 24px;
	color: var(--dark-gray-text);
	margin-bottom: 6px;
}

.verification-header .email {
	font-size: 24px;
	color: var(--black);
	font-weight: 700;
	margin-bottom: 40px;
}

@media only screen and (max-width: 1080px) {
	.verification: {
		padding: 80px 175px 0px 175px;
	}

	.verification-header .profile-pic {
		margin-bottom: 24px;
	}

	.verification-header .mailbox {
		width: 96px;
		height: 96px;
	}

	.verification-header .title {
		font-size: 32px;
		line-height: 48px;
	}

	.verification-header .description {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.verification-header .email {
		font-size: 20px;
		margin-bottom: 32px;
	}
}

@media only screen and (max-width: 600px) {
	.verification: {
		padding: 40px 16px 0px 16px;
	}

	.verification-header .profile-pic {
		margin-bottom: 16px;
	}

	.verification-header .mailbox {
		width: 96px;
		height: 96px;
		width: 72px;
		height: 72px;
	}

	.verification-header .title {
		font-size: 20px;
		line-height: 150%;
		margin-bottom: 12px;
	}

	.verification-header .description {
		font-size: 14px;
		margin-bottom: 4px;
	}

	.verification-header .email {
		font-size: 14px;
		margin-bottom: 24px;
	}
}

.verification-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.verification-input .resend {
	margin-bottom: 64px;
	color: var(--primary-color);
	font-weight: 700;
	font-size: 24px;
	cursor: pointer;
}

.verification-input .countdown {
	margin-bottom: 64px;
	color: #a3a3a3;
	font-weight: 700;
	font-size: 24px;
}

.verification-input .input-container {
	position: relative;
	display: flex;
	justify-content: center;
}

.verification-input .input {
	margin: auto;
	width: 505px !important;
	margin-bottom: 140px;
}

.verification-input .input input {
	width: 64px !important;
	margin-right: 24px;
	border: none !important;
	border-bottom: 2px solid var(--primary-color) !important;
	border-radius: 0px !important;
	font-size: 32px;
	font-family: 'Roboto', 'Helvetica Neue', Arial;
	font-weight: 700;
	color: var(--black);
	caret-color: var(--primary-color);
}

.verification-input .input input:last-child {
	margin-right: 0px;
}

.verification-input .error-label {
	color: var(--danger);
	position: absolute;
	font-size: 24px;
	margin: auto;
	top: 76px;
}

.verification-input form {
	width: 100%;
	text-align: center;
}

.verification-input .submit-button {
	width: 536px;
	height: 68px;
	font-size: 20px;
	margin-bottom: 24px;
}

.verification-input .change-email {
	font-size: 24px;
	color: var(--black);
}

.verification-input .change-email span {
	color: var(--primary-color);
}

@media only screen and (max-width: 1080px) {
	.verification-input .resend,
	.verification-input .countdown {
		margin-bottom: 72px;
		font-size: 20px;
	}

	.verification-input .input {
		width: 408px !important;
		margin-bottom: 134px;
	}

	.verification-input .input input {
		width: 48px !important;
		margin-right: 24px;
	}

	.verification-input .error-label {
		font-size: 20px;
	}

	.verification-input .submit-button {
		width: 417px;
		height: 60px;
		margin-bottom: 20px;
	}

	.verification-input .change-email {
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.verification-input .resend,
	.verification-input .countdown {
		margin-bottom: 24px;
		font-size: 14px;
	}

	.verification-input .input {
		width: 272px !important;
		margin-bottom: 85px;
	}

	.verification-input .error-label {
		font-size: 14px;
		margin: auto;
		top: 68px;
	}

	.verification-input .input input {
		width: 32px !important;
		margin-right: 16px;
		font-size: 20px;
	}

	.verification-input .submit-button {
		width: 100%;
		height: 48px;
		max-width: 288px;
		font-size: 14px;
	}

	.verification-input .change-email {
		font-size: 12px;
	}
}

.verification-modal {
	padding: 129px 0px;
}

.verification-modal .verification-modal-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.verification-modal .check-icon {
	color: var(--primary-color);
	font-size: 136px;
	text-align: center;
	margin-bottom: 48px;
}

.verification-modal .title {
	color: var(--black);
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 16px;
}

.verification-modal .description {
	color: var(--dark-gray-text);
	font-size: 24px;
	margin-bottom: 64px;
}

.verification-modal button.continue {
	width: 536px;
	height: 68px;
}

@media only screen and (max-width: 1080px) {
	.verification-modal {
		padding: 330px 0px;
	}

	.verification-modal .check-icon {
		margin-bottom: 40px;
		font-size: 96px;
	}

	.verification-modal .title {
		margin-bottom: 12px;
		font-size: 32px;
	}

	.verification-modal .description {
		font-size: 20px;
		margin-bottom: 48px;
	}

	.verification-modal button.continue {
		width: 417px;
		height: 60px;
	}
}

@media only screen and (max-width: 600px) {
	.verification-modal {
		padding: 136px 15px;
	}

	.verification-modal .check-icon {
		margin-bottom: 82px;
		font-size: 72px;
	}

	.verification-modal .title {
		font-size: 20px;
	}

	.verification-modal .description {
		font-size: 14px;
	}

	.verification-modal button.continue {
		width: 288px;
		height: 48px;
	}
}

.house-loader .loader {
	position: fixed;
	top: 50vh;
	left: 50vw;
	z-index: 10;
	height: 120px;
	width: 120px;
	transform: translate(-50%, -50%)
}

.tasks .tasks-container {
	width: 620px;
}

@media only screen and (max-width: 1080px) {
	.tasks .tasks-container {
		width: 460px;
	}

	/* File Upload Dropzone */

	.tasks .file-upload-dropzone .base {
    height: 206px;
  }

  .tasks .file-upload-dropzone .label-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .tasks .file-upload-dropzone .label-subtitle {
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }

  .tasks .file-upload-dropzone .image-container {
    margin-bottom: 19px
  }

  .tasks .file-upload-dropzone .drag-and-drop-image {
    width: 24px;
    height: 24px;
  }

	/* Multiple File Upload Dropzone */

	.tasks .multiple-file-upload-dropzone .base {
    height: 206px;
  }

  .tasks .multiple-file-upload-dropzone .base.filled{
    height: 110px;
    margin-bottom: 24px;
  }

  .tasks .multiple-file-upload-dropzone .base.filled.reject + .message-label {
    top: 110px;
  }

  .tasks .multiple-file-upload-dropzone .label-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .tasks .multiple-file-upload-dropzone .label-subtitle {
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }

  .tasks .multiple-file-upload-dropzone .image-container {
    margin-bottom: 19px
  }

  .tasks .multiple-file-upload-dropzone .drag-and-drop-image {
    width: 24px;
    height: 24px;
  }

  .tasks .multiple-file-upload-dropzone .file-item:not(:last-of-type) {
    margin-bottom: 16px
  }

	/* File Item */

	.tasks .file-item .gray-document {
    height: 35px;
    width: 27.5px;
  }

  .tasks .file-item .file-label-container{
    margin-left: 22.25px;
  }

  .tasks .file-item .file-name {
    font-size: 16px;
    line-height: 24px;
    width: 280px;
  }
  
  .tasks .file-item .file-size {
    font-size: 12px;
    line-height: 24px
  }
  
  .tasks .file-item .delete-button {
    width: 14px;
    height: 18px;
  }

  /* Form Modal */

  .tasks .modal-form-container {
    width: 481px;
  }

  .tasks .modal-form-container .modal-exit-button {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
  }

  .tasks .modal-form-container .modal-title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .tasks .modal-form-container .modal-subtitle {
    font-size: 20px;
    line-height: 30px;
  }

  .tasks .modal-form-container .modal-submit-button {
    font-size: 20px;
    line-height: 30px;
    height: 60px;
  }

}

@media only screen and (max-width: 767px) {
	.tasks .tasks-container {
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {

	/* File Upload Dropzone */

	.tasks .file-upload-dropzone .base {
    height: 121px;
  }

  .tasks .file-upload-dropzone .label-title {
    font-size: 14px;
    line-height: 21px;
  }
  
  .tasks .file-upload-dropzone .label-subtitle {
    font-size: 10px;
    line-height: 18px;
    margin-top: 2px;
  }

  .tasks .file-upload-dropzone .image-container {
    margin-bottom: 8px
  }

  .tasks .file-upload-dropzone .message-label {
    font-size: 10px;
    line-height: 15px;
    margin: 0px 0 0 12px;
  }

	/* Multiple File Upload Dropzone */

	.tasks .multiple-file-upload-dropzone .base {
    height: 121px;
  }

  .tasks .multiple-file-upload-dropzone .base.filled{
    height: 65px;
    margin-bottom: 20px;
  }

  .tasks .multiple-file-upload-dropzone .base.filled.reject + .message-label {
    top: 65px;
  }

  .tasks .multiple-file-upload-dropzone .label-title {
    font-size: 14px;
    line-height: 21px;
  }
  
  .tasks .multiple-file-upload-dropzone .label-subtitle {
    font-size: 10px;
    line-height: 18px;
    margin-top: 2px;
  }

  .tasks .multiple-file-upload-dropzone .image-container {
    margin-bottom: 8px
  }

  .tasks .multiple-file-upload-dropzone .message-label {
    font-size: 10px;
    line-height: 15px;
    margin: 0px 0 0 12px;
  }

  .tasks .multiple-file-upload-dropzone .file-item:not(:last-of-type) {
    margin-bottom: 12px
  }

	/* File Item */

	.tasks .file-item {
    padding: 13px 17px;
  }

  .tasks .file-item .gray-document {
    height: 28px;
    width: 22px;
  }

  .tasks .file-item .file-label-container{
    margin-left: 9px;
  }

  .tasks .file-item .file-name {
    font-size: 12px;
    line-height: 18px;
    width: 160px;
  }
  
  .tasks .file-item .file-size {
    font-size: 8px;
    line-height: 12px;
  }
  
  .tasks .file-item .delete-button {
    width: 11.67px;
    height: 15px;
  }

  /* Form Modal */

  .tasks .modal-form-container {
    width: 288px;
    padding: 16px;
  }

  .tasks .modal-form-container .modal-exit-button {
    width: 24px;
    height: 24px;
    margin-bottom: 16px;
  }

  .tasks .modal-form-container .modal-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  .tasks .modal-form-container .modal-subtitle {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .tasks .modal-form-container .modal-input-container {
    margin-bottom: 16px;
  }

  .tasks .modal-form-container .modal-submit-button {
    font-size: 14px;
    line-height: 21px;
    height: 48px;
  }

}

.tasks-header {
	font-weight: 900;
	margin-bottom: 40px;
}

.tasks-header .sub-header {
	color: var(--black);
	text-transform: uppercase;
	margin-bottom: 8px;
	font-size: 16px;
	line-height: 24px;
}

.tasks-header .header {
	font-size: 56px;
	line-height: 68px;
	margin-bottom: 24px;
}

.tasks-header .description {
	font-size: 20px;
	font-weight: 400;
	color: var(--dark-gray-text);
	margin-bottom: 48px;
}

@media only screen and (max-width: 1080px) {
	.tasks-header {
		font-size: 44px;
		line-height: 57px;
		margin-bottom: 32px;
	}

	.tasks-header .header {
		font-size: 44px;
		line-height: 57px;
	}

	.tasks-header .sub-header {
		margin-bottom: 4px;
	}

	.tasks-header .description {
		font-size: 16px;
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-header {
		margin-bottom: 24px;
	}

	.tasks-header .sub-header {
		font-size: 12px;
		margin-bottom: 0px;
	}

	.tasks-header .header {
		font-size: 20px;
		line-height: 150%;
		margin-bottom: 12px;
	}

	.tasks-header .description {
		font-size: 12px;
		line-height: 21px;
		margin-bottom: 24px;
	}
}

.tasks-applicant-content {
	padding: 32px 32px 40px 32px;
	border-bottom: 1px solid var(--light-gray);
}

.tasks-applicant-content .content-title {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 24px;
}

.tasks-applicant-content .content-notes {
	padding-left: 20px;
	margin-bottom: 36px;
}

.tasks-applicant-content .note {
	margin-bottom: 12px;
	font-size: 20px;
}

.tasks-applicant-content .upload-examples {
	display: flex;
	margin-bottom: 16px;
}

.tasks-applicant-content .upload-examples > div {
	width: 240px;
	height: 148px;
	border: 1px solid var(--light-gray);
	border-radius: 16px;
	z-index: 1;
	overflow: hidden;
	position: relative;
}

.tasks-applicant-content .good-example {
	margin-right: 36px;
}

.tasks-applicant-content .bad-example img {
	position: relative;
	top: 33px;
	left: 25px;
}

.tasks-applicant-content .icon-container {
	position: absolute;
	right: 8px;
	top: 2px;
	background-color: white;
	height: 32px;
	width: 32px;
	border-radius: 25px;
}

.tasks-applicant-content .icon-container svg {
	font-size: 32px;
}

.tasks-applicant-content .good-example svg {
	color: var(--primary-color);
}

.tasks-applicant-content .bad-example svg {
	color: var(--danger);
}

.tasks-applicant-content .disclaimer {
	font-size: 20px;
	margin-bottom: 40px;
}

@media only screen and (max-width: 1080px) {
	.tasks-applicant-content {
		padding: 20px 24px 32px;
	}

	.tasks-applicant-content .content-title {
		font-size: 16px;
		margin-bottom: 16px;
	}

	.tasks-applicant-content .content-notes {
		margin-bottom: 25px;
	}

	.tasks-applicant-content .note {
		font-size: 16px;
	}

	.tasks-applicant-content .upload-examples {
		margin-bottom: 12px;
	}

	.tasks-applicant-content .icon-container {
		height: 28px;
		width: 28px;
		z-index: 2;
	}

	.tasks-applicant-content .icon-container svg {
		font-size: 28px;
	}

	.tasks-applicant-content .upload-examples > div,
	.tasks-applicant-content img {
		width: 199px;
		height: 125px;
	}

	.tasks-applicant-content .good-example {
		margin-right: 25px;
	}

	.tasks-applicant-content .bad-example img {
		top: 25px;
	}

	.tasks-applicant-content .disclaimer {
		font-size: 12px;
		margin-bottom: 32px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-applicant-content {
		padding: 16px 16px 20px;
	}
	.tasks-applicant-content .content-title {
		font-size: 12px;
		margin-bottom: 16px;
	}
	.tasks-applicant-content .content-notes {
		margin-bottom: 16px;
	}
	.tasks-applicant-content .note {
		margin-bottom: 8px;
		font-size: 12px;
	}

	.tasks-applicant-content .upload-examples {
		margin-bottom: 4px;
	}

	.tasks-applicant-content .upload-examples > div {
		border-radius: 8px;
	}

	.tasks-applicant-content .icon-container {
		height: 17px;
		width: 17px;
	}

	.tasks-applicant-content .icon-container svg {
		font-size: 17px;
	}

	.tasks-applicant-content .upload-examples > div,
	.tasks-applicant-content img {
		width: 120px;
		height: 74px;
	}

	.tasks-applicant-content .good-example {
		margin-right: 16px;
	}

	.tasks-applicant-content .bad-example img {
		top: 15px;
	}

	.tasks-applicant-content .disclaimer {
		font-size: 8px;
		margin-bottom: 20px;
	}
}

.tasks-applicant-accordion .MuiAccordionSummary-root {
	padding: 32px 42px 36px 32px;
}

.tasks-applicant-accordion .MuiAccordionSummary-content {
	margin: 0px;
}

.tasks-applicant-accordion .title-container {
	border: 0px;
	display: flex;
	align-items: center;
}

.tasks-applicant-accordion .title-container svg {
	font-size: 24px;
	color: var(--light-gray);
	padding-right: 24px;
}

.tasks-applicant-accordion .title-container .title {
	border: 0px;
	padding: 0px;
	font-size: 24px;
	line-height: 36px;
	color: var(--dark-gray-text);
	font-weight: 400;
}

.tasks-applicant-accordion .arrow-icon {
	color: var(--light-gray-text);
	font-size: 24px;
}

.tasks-applicant-accordion .success svg {
	color: var(--primary-color)
}

.tasks-applicant-accordion .success.arrow-icon {
	display: none;
}

.tasks-applicant-accordion .content-container {
	padding: 0px 32px 36px;
	width: 100%;
	box-sizing: border-box;
}

.tasks-applicant-accordion .description {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;
	color: var(--dark-gray-text);
	margin-bottom: 24px;
}

.tasks-applicant-accordion .button-container {
	text-align: right;
	padding: 32px 32px 24px 32px;
}

.tasks-applicant-accordion .button.MuiButtonBase-root {
	width: 207px;
}

.tasks-applicant-accordion  .MuiAccordionDetails-root {
	padding: 0px;
}

.tasks-applicant-accordion .MuiAccordionSummary-content.Mui-expanded {
	margin: 0px;
}

.tasks-applicant-accordion .MuiIconButton-root {
	padding: 0px; 
}

.tasks-applicant-accordion .MuiAccordionSummary-root {
	min-height: 0;
}

@media only screen and (max-width: 1080px) {
	.tasks-applicant-accordion .MuiAccordionSummary-root {
		padding: 20px 30px 20px 24px;
	}

	.tasks-applicant-accordion .title-container svg {
		font-size: 16px;
		padding-right: 28px;
	}

	.tasks-applicant-accordion .title-container .title {
		font-size: 16px;
		line-height: 24px;
	}

	.tasks-applicant-accordion .content-container {
		padding: 4px 24px 40px;
	}

	.tasks-applicant-accordion .description {
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 16px;
	}

	.tasks-applicant-accordion .button-container {
		padding: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-applicant-accordion .MuiAccordionSummary-root {
		padding: 16px 21px 16px 16px;
	}

	.tasks-applicant-accordion .title-container svg {
		font-size: 12px;
		padding-right: 12px;
	}

	.tasks-applicant-accordion .title {
		font-size: 14px;
	}

	.tasks-applicant-accordion .content-container {
		padding: 0px 16px 32px;
	}

	.tasks-applicant-accordion .description {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 12px;
	}

	.tasks-applicant-accordion .button-container {
		padding: 16px;
	}

	.tasks-applicant-accordion .button.MuiButtonBase-root {
		width: 100%;
		font-size: 14px;
	}
}

.tasks-property-accordion .MuiAccordionSummary-root {
	padding: 32px 42px 36px 32px;
}

.tasks-property-accordion .MuiAccordionSummary-content {
	margin: 0px;
}

.tasks-property-accordion .title-container {
	border: 0px;
	display: flex;
	align-items: center;
}

.tasks-property-accordion .title-container svg {
	font-size: 24px;
	color: var(--light-gray);
	padding-right: 24px;
}

.tasks-property-accordion .title-container .title {
	border: 0px;
	padding: 0px;
	font-size: 24px;
	line-height: 36px;
	color: var(--dark-gray-text);
	font-weight: 400;
}

.tasks-property-accordion .arrow-icon {
	color: var(--light-gray-text);
	font-size: 24px;
}

.tasks-property-accordion .success svg {
	color: var(--primary-color)
}

.tasks-property-accordion .success.arrow-icon {
	display: none;
}

.tasks-property-accordion .content-container {
	padding: 0px 32px 36px;
	width: 100%;
	box-sizing: border-box;
}

.tasks-property-accordion .button-container {
	text-align: right;
	padding: 32px 32px 24px 32px;
}

.tasks-property-accordion .button.MuiButtonBase-root {
	width: 207px;
}

.tasks-property-accordion  .MuiAccordionDetails-root {
	padding: 0px;
}

.tasks-property-accordion .MuiAccordionSummary-content.Mui-expanded {
	margin: 0px;
}

.tasks-property-accordion .MuiIconButton-root {
	padding: 0px; 
}

.tasks-property-accordion .MuiAccordionSummary-root {
	min-height: 0;
}

@media only screen and (max-width: 1080px) {
	.tasks-property-accordion .MuiAccordionSummary-root {
		padding: 20px 30px 20px 24px;
	}

	.tasks-property-accordion .title-container svg {
		font-size: 16px;
		padding-right: 28px;
	}

	.tasks-property-accordion .title-container .title {
		font-size: 16px;
		line-height: 24px;
	}

	.tasks-property-accordion .content-container {
		padding: 4px 24px 40px;
	}

	.tasks-property-accordion .button-container {
		padding: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-property-accordion .MuiAccordionSummary-root {
		padding: 16px 21px 16px 16px;
	}

	.tasks-property-accordion .title-container svg {
		font-size: 12px;
		padding-right: 12px;
	}

	.tasks-property-accordion .title {
		font-size: 14px;
	}

	.tasks-property-accordion .content-container {
		padding: 0px 16px 32px;
	}

	.tasks-property-accordion .button-container {
		padding: 16px;
	}

	.tasks-property-accordion .button.MuiButtonBase-root {
		width: 100%;
		font-size: 14px;
	}
}

.tasks-additional-accordion .MuiAccordionSummary-root {
	padding: 32px 42px 36px 32px;
}

.tasks-additional-accordion .MuiAccordionSummary-content {
	margin: 0px;
}

.tasks-additional-accordion .title-container {
	border: 0px;
	display: flex;
	align-items: center;
}

.tasks-additional-accordion .title-container svg {
	font-size: 24px;
	color: var(--light-gray);
	padding-right: 24px;
}

.tasks-additional-accordion .title-container .title {
	border: 0px;
	padding: 0px;
	font-size: 24px;
	color: var(--dark-gray-text);
	font-weight: 400;
}

.tasks-additional-accordion .arrow-icon {
	color: var(--light-gray-text);
	font-size: 24px;
}

.tasks-additional-accordion .success svg {
	color: var(--primary-color)
}

.tasks-additional-accordion .success.arrow-icon {
	display: none;
}

.tasks-additional-accordion .content-container {
	padding: 0px 32px 36px;
	width: 100%;
	box-sizing: border-box;
}

.tasks-additional-accordion .button-container {
	text-align: right;
	padding: 32px 32px 24px 32px;
}

.tasks-additional-accordion .button.MuiButtonBase-root {
	width: 207px;
}
.tasks-additional-accordion  .MuiAccordionDetails-root {
	padding: 0px;
}

.tasks-additional-accordion .MuiAccordionSummary-content.Mui-expanded {
	margin: 0px;
}

@media only screen and (max-width: 1080px) {
	.tasks-additional-accordion .MuiAccordionSummary-root {
		padding: 20px 30px 20px 24px;
	}

	.tasks-additional-accordion .title-container svg {
		font-size: 16px;
		padding-right: 28px;
	}

	.tasks-additional-accordion .title {
		font-size: 16px;
	}

	.tasks-additional-accordion .content-container {
		padding: 4px 24px 40px;
	}

	.tasks-additional-accordion .button-container {
		padding: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-additional-accordion .MuiAccordionSummary-root {
		padding: 16px 21px 16px 16px;
	}

	.tasks-additional-accordion .title-container svg {
		font-size: 12px;
		padding-right: 12px;
	}

	.tasks-additional-accordion .title {
		font-size: 14px;
	}

	.tasks-additional-accordion .content-container {
		padding: 0px 16px 32px;
	}

	.tasks-additional-accordion .button-container {
		padding: 16px;
	}

	.tasks-additional-accordion .button.MuiButtonBase-root {
		width: 100%;
		font-size: 14px;
	}
}

.tasks-panel {
	border: 1px solid var(--light-gray);
	border-radius: 8px;
	color: var(--dark-gray-text);
	margin-bottom: 48px;
}

.tasks-panel .title {
	padding: 24px 32px;
	color: var(--dark-gray-text);
	font-size: 20px;
	font-weight: 700;
	border-bottom: 1px solid var(--light-gray);
	margin-bottom: 0px;
}

@media only screen and (max-width: 1080px) {
	.tasks-panel {
		margin-bottom: 40px;
	}

	.tasks-panel .title {
		padding: 20px 24px;
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-panel {
		margin-bottom: 24px;
	}

	.tasks-panel .title {
		padding: 12px 19px;
		font-size: 14px;
	}
}

.multiple-file-upload-dropzone {
  position: relative;
}

.multiple-file-upload-dropzone .base {
  height: 311px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F4F4F4;
  border: 1px dashed var(--light-gray-text);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.multiple-file-upload-dropzone .base.filled {
  height: 156px;
  margin-bottom: 32px;
}

.multiple-file-upload-dropzone .base.filled.reject + .message-label {
  top: 156px;
}

.multiple-file-upload-dropzone .base.reject {
  background: #FFECEC;
  border: 1px dashed #D65B5B;
}

.multiple-file-upload-dropzone .base.accept {
  background: #F8FEFC;
  border: 1px dashed var(--primary-color);
}

.multiple-file-upload-dropzone .label-title {
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #767676;
}

.multiple-file-upload-dropzone .label-subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #767676;
  margin-top: 8px;
}

.multiple-file-upload-dropzone .image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.multiple-file-upload-dropzone .drag-and-drop-image {
  width: 32px;
  height: 32px;
}

.multiple-file-upload-dropzone .message-label {
  position: absolute;
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 0 16px;
}

.multiple-file-upload-dropzone .message-label.reject {
  color: var(--danger); 
}

.multiple-file-upload-dropzone .file-item:not(:last-of-type) {
  margin-bottom: 20px
}


@media only screen and (max-width: 767px) {
  .multiple-file-upload-dropzone .base {
    height: 206px;
  }

  .multiple-file-upload-dropzone .base.filled{
    height: 110px;
    margin-bottom: 24px;
  }

  .multiple-file-upload-dropzone .base.filled.reject + .message-label {
    top: 110px;
  }

  .multiple-file-upload-dropzone .label-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .multiple-file-upload-dropzone .label-subtitle {
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }

  .multiple-file-upload-dropzone .image-container {
    margin-bottom: 19px
  }

  .multiple-file-upload-dropzone .drag-and-drop-image {
    width: 24px;
    height: 24px;
  }

  .multiple-file-upload-dropzone .file-item:not(:last-of-type) {
    margin-bottom: 16px
  }
}

@media only screen and (max-width: 600px) {
  .multiple-file-upload-dropzone .base {
    height: 121px;
  }

  .multiple-file-upload-dropzone .base.filled{
    height: 65px;
    margin-bottom: 20px;
  }

  .multiple-file-upload-dropzone .base.filled.reject + .message-label {
    top: 65px;
  }

  .multiple-file-upload-dropzone .label-title {
    font-size: 14px;
    line-height: 21px;
  }
  
  .multiple-file-upload-dropzone .label-subtitle {
    font-size: 10px;
    line-height: 18px;
    margin-top: 2px;
  }

  .multiple-file-upload-dropzone .image-container {
    margin-bottom: 8px
  }

  .multiple-file-upload-dropzone .message-label {
    font-size: 10px;
    line-height: 15px;
    margin: 0px 0 0 12px;
  }

  .multiple-file-upload-dropzone .file-item:not(:last-of-type) {
    margin-bottom: 12px
  }
}
.file-item{
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 16px 23px 16px 23px;
  display: flex;
  box-sizing: border-box;
}

.file-item .gray-document-container {
  display: flex;
  align-items: center;
}

.file-item .gray-document {
  height: 42px;
  width: 33px;
}

.file-item .file-label-container{
  margin-left: 15.5px;
}

.file-item .file-name {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-item .file-size {
  font-weight: normal;
  font-size: 16px; 
  line-height: 24px;
  color: #767676;
}

.file-item .file-button-container {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.file-item .delete-button {
  cursor: pointer;
  width: 18.67px;
  height: 24px;
}

@media only screen and (max-width: 767px) {
  .file-item .gray-document {
    height: 35px;
    width: 27.5px;
  }

  .file-item .file-label-container{
    margin-left: 22.25px;
  }

  .file-item .file-name {
    font-size: 16px;
    line-height: 24px;
    width: 280px;
  }
  
  .file-item .file-size {
    font-size: 12px;
    line-height: 24px
  }
  
  .file-item .delete-button {
    width: 14px;
    height: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .file-item {
    padding: 13px 17px;
  }

  .file-item .gray-document {
    height: 28px;
    width: 22px;
  }

  .file-item .file-label-container{
    margin-left: 9px;
  }

  .file-item .file-name {
    font-size: 12px;
    line-height: 18px;
    width: 160px;
  }
  
  .file-item .file-size {
    font-size: 8px;
    line-height: 12px;
  }
  
  .file-item .delete-button {
    width: 11.67px;
    height: 15px;
  }
}
.tasks-additional-employment .radio-container {
  margin-bottom: 32px;
}

.tasks-additional-employment .radio-button:not(:last-child) {
  margin-bottom: 16px;
}

.tasks-additional-employment .input-container {
  margin-bottom: 30px;
}

.tasks-additional-employment .text-field:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) { 
  .tasks-additional-employment .radio-container {
    margin-bottom: 24px;
  }

  .tasks-additional-employment .input-container {
    margin-bottom: 0px;
  }

  .tasks-additional-employment .text-field:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) { 
  .tasks-additional-employment .radio-container {
    margin-bottom: 16px;
  }
  
  .tasks-additional-employment .radio-button:not(:last-child) {
    margin-bottom: 4px;
  }
  
  .tasks-additional-employment .input-container {
    margin-bottom: 0px;
  }
  
  .tasks-additional-employment .text-field:not(:last-child) {
    margin-bottom: 24px;
  }
}

.tasks-additional-household .radio-container {
  margin-bottom: 4px;
}

.tasks-additional-household .radio-button:not(:last-child) {
  margin-bottom: 16px;
}

@media only screen and (max-width: 600px) { 
  .tasks-additional-household .radio-container {
    margin-bottom: 8px;
  }
  .tasks-additional-household .radio-button:not(:last-child) {
    margin-bottom: 4px;
  }

}

.tasks-additional-property .radio-container {
  margin-bottom: 32px;
}

.tasks-additional-property .radio-button:not(:last-child) {
  margin-bottom: 16px;
}

.tasks-additional-property .input-container {
  margin-bottom: 30px;
}

.tasks-additional-property .select-field:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) { 
  .tasks-additional-property .radio-container {
    margin-bottom: 24px;
  }

  .tasks-additional-property .input-container {
    margin-bottom: 0px;
  }

  .tasks-additional-property .select-field:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) { 
  .tasks-additional-property .radio-container {
    margin-bottom: 16px;
  }
  
  .tasks-additional-property .radio-button:not(:last-child) {
    margin-bottom: 4px;
  }
  
  .tasks-additional-property .input-container {
    margin-bottom: 0px;
  }
  
  .tasks-additional-property .select-field:not(:last-child) {
    margin-bottom: 24px;
  }
}

.overview-help {
	border-radius: 8px;
	border: 1px solid var(--light-gray);
	padding: 24px 32px;
	margin-bottom: 40px;
}

.overview-help .title {
	font-size: 24px;
	line-height: 36px;
	font-weight: 700;
	color: var(--black);
	margin-bottom: 16px;
}

.overview-help .description {
	font-size: 20px;
	line-height: 30px;
	color: var(--dark-gray-text);
	margin-bottom: 48px;
}

.overview-help .button.MuiButton-outlined {
	font-size: 16px;
	padding: 12px 26px;
	height: 48px;
}

@media only screen and (max-width: 1080px) {
	.overview-help {
		padding: 16px 20px;
		margin-bottom: 24px;
	}

	.overview-help .description {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-help {
		padding: 16px 16px;
		margin-bottom: 16px;
	}

	.overview-help .title {
		font-size: 20px;
		line-height: 26px;
	}

	.overview-help .description {
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 32px;
	}

	.overview-help .button.MuiButton-outlined {
		font-size: 16px;
		padding: 10px 24px;
		height: 43px;
	}
}

@media only screen and (max-width: 480px) {
	.overview-help .button.MuiButton-outlined {
		width: 100%;
	}
}

.coming-soon-main .coming-soon-main-container {
  max-width: 1072px;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 65px;
}

.coming-soon-main .content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.coming-soon-main img {
  height: auto;
  width: 377px;
}

.coming-soon-main .input-container {
  width: 440px;
}

.coming-soon-main .title {
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 12px;
}

.coming-soon-main .sub-title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 32px;
}

.coming-soon-main div:nth-child(3) {
  margin-bottom: 40px;
}

.coming-soon-main .button {
  width: 207px;
}

.coming-soon-main .MuiCircularProgress-colorPrimary {
  color: var(--primary-color);
  width: 30px !important;
  height: 30px !important;
}

@media only screen and (max-width: 1080px) {

  .coming-soon-main .content-container {
    display: flex;
    flex-direction: column;
    max-width: 472px;
    margin: auto;
  }

  .coming-soon-main img {
    width: 238px;
    margin: 0px auto 72px auto;
  }

  .coming-soon-main .input-container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  
  .coming-soon-main img {
    width: 238px;
    margin: 0px auto 80px auto;
  }

  .coming-soon-main .button {
    height: 60px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {

  .coming-soon-main .coming-soon-main-container {
    margin-top: 40px;
  }

  .coming-soon-main .content-container {
    display: flex;
    flex-direction: column;
    max-width: 288px;
  }

  .coming-soon-main img {
    width: 119px;
    margin: 0px auto 40px auto;
  }

  .coming-soon-main .title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  .coming-soon-main .sub-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .coming-soon-main .button {
    height: 60px;
    font-size: 20px;
  }

  .coming-soon-main div:nth-child(3) {
    margin-bottom: 32px;
  }

  .coming-soon-main .button {
    height: 48px;
    font-size: 14px;
    width: 136px;
  }

  .coming-soon-main .MuiCircularProgress-colorPrimary {
    color: var(--primary-color);
    width: 21px !important;
    height: 21px !important;
  }
}
.form-status-notice.success {
	color: var(--primary-color);
	background-color: #f8fefc;
	border-color: #2fa67f;
}

.form-status-notice .text {
	font-weight: 700;
	margin-bottom: 0px;
}

.form-status-notice .icon-container {
	position: relative;
	top: 6px;
}

.form-status-notice .icon-container svg {
	height: 20px;
}

@media only screen and (max-width: 1080px) {
  .form-status-notice .icon-container {
    top: 2px;
  }
}

@media only screen and (max-width: 600px) {
  .form-status-notice .icon-container {
    top: 0px;
  }
}

.tasks-helper {
  margin-bottom: 48px;
}

.tasks-helper .text {
  font-size: 20px;
  line-height: 30px;
  color: var(--dark-gray-text);
}

.tasks-helper .highlight {
  color: #2FA67F;
  cursor: pointer;
  font-weight: 700 !important;
}

.tasks-helper .item:not(:last-child) {
  margin-bottom: 12px
}

@media only screen and (max-width: 1080px) {
  .tasks-helper {
    margin-bottom: 32px;
  }

  .tasks-helper .text {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .tasks-helper {
    margin-bottom: 24px;
  }

  .tasks-helper .text {
    font-size: 12px;
    line-height: 21px;
  }

  .tasks-helper .item:not(:last-child) {
    margin-bottom: 8px
  }
}
.process-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .process-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .process-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
.looking-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .looking-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .looking-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
.looking-timeline-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .looking-timeline-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .looking-timeline-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }
}

.property-type-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .property-type-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .property-type-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
.property-complete-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .property-complete-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .property-complete-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
.marital-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.marital-preapproval .input-container {
  margin-top: 32px;
}

.marital-preapproval .children-field-container {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .marital-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .marital-preapproval .input-container {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .marital-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .marital-preapproval .children-field-container {
    margin-top: 24px;
  }
}
.employment-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.employment-preapproval .input-container {
  margin-top: 32px;
}

@media only screen and (max-width: 767px) {
  .employment-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .employment-preapproval .input-container {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .employment-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .employment-preapproval .input-container {
    margin-top: 16px;
  }
}
.employment-details-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
}

.employment-details-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  .employment-details-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .employment-details-preapproval .col-2 {
    grid-template-columns: 1fr;
  }

  .employment-details-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }
}








.current-living-preapproval .field-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 32px;
}

.current-living-preapproval .input-container:not(:last-child) {
  margin-bottom: 40px;
}

.current-living-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.current-living-preapproval .custom-radio-container {
  margin-bottom: 32px;
}

.current-living-preapproval .status-field-container {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .current-living-preapproval .field-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 24px;
  }

  .current-living-preapproval .input-container:not(:last-child) {
    margin-bottom: 40px;
  }

  .current-living-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .current-living-preapproval .custom-radio-container {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .current-living-preapproval .field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .current-living-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }

  .current-living-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .current-living-preapproval .custom-radio-container {
    margin-bottom: 16px;
  }

  .current-living-preapproval .status-field-container {
    margin-top: 24px;
  }
}




.property-describe-preapproval .field-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 24px;
}

.property-describe-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
}

.property-describe-preapproval .fields-container {
  margin: 48px 0;
}

.property-describe-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  .property-describe-preapproval .field-title {
    font-size: 20px;
    line-height: 30px;
  }

  .property-describe-preapproval .field-title:first-of-type {
    margin-top: 40px;
  }

  .property-describe-preapproval .fields-container {
    margin: 40px 0;
  }

  .property-describe-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .property-describe-preapproval .field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .property-describe-preapproval .col-2 {
    grid-gap: 16px;
    gap: 16px;
  }

  .property-describe-preapproval .field-title:first-of-type {
    margin-top: 0px;
  }

  .property-describe-preapproval .fields-container {
    margin: 24px 0 32px 0;
  }

  .property-describe-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }

  .property-describe-preapproval .custom-radio-container {
    grid-template-columns: 1fr;
    grid-gap: 8px;
    gap: 8px;
  }
}




.parking-describe-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.parking-describe-preapproval .parking-container {
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .parking-describe-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .parking-describe-preapproval .parking-container {
    margin-top: 72px;
  }
}

@media only screen and (max-width: 600px) {
  .parking-describe-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .parking-describe-preapproval .parking-container {
    margin-top: 56px;
  }
}
.summary-targeted-property .field:first-child {
	grid-column-start: 1;
	grid-column-end: 3;
}

@media only screen and (max-width: 374px) {
	.summary-targeted-property .fields {
		grid-template-columns: 1fr 1fr;
	}

	.summary-targeted-property .field:first-child {
		grid-column-start: 1;
		grid-column-end: 3;
	}
}


